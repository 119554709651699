(function (cjs, an) {
  var p; // shortcut to reference prototypes
  var lib = {};
  var ss = {};
  var img = {};
  lib.ssMetadata = [
    {
      name: 'confeti_atlas_1',
      frames: [
        [1026, 657, 85, 104],
        [1109, 326, 104, 127],
        [1585, 412, 115, 114],
        [1196, 658, 93, 69],
        [1527, 0, 219, 228],
        [761, 516, 156, 146],
        [1237, 313, 149, 203],
        [1712, 396, 149, 202],
        [587, 0, 358, 514],
        [1237, 0, 288, 311],
        [1527, 230, 183, 180],
        [587, 516, 172, 165],
        [947, 326, 160, 189],
        [1863, 396, 175, 171],
        [1748, 0, 179, 210],
        [919, 517, 138, 138],
        [1059, 517, 138, 138],
        [1199, 518, 138, 138],
        [1339, 568, 138, 138],
        [1479, 568, 138, 138],
        [1863, 569, 138, 138],
        [1619, 600, 138, 138],
        [0, 606, 138, 138],
        [140, 606, 136, 125],
        [1929, 190, 19, 12],
        [2004, 336, 41, 50],
        [1189, 455, 40, 13],
        [761, 664, 63, 27],
        [1510, 313, 15, 9],
        [278, 606, 134, 108],
        [2003, 569, 43, 39],
        [1759, 600, 93, 96],
        [414, 606, 108, 99],
        [1712, 303, 23, 8],
        [947, 0, 288, 324],
        [1619, 528, 61, 68],
        [1109, 455, 78, 29],
        [1113, 657, 81, 87],
        [1952, 336, 50, 48],
        [0, 0, 585, 604],
        [1388, 313, 120, 97],
        [1339, 518, 47, 46],
        [919, 657, 105, 87],
        [1109, 486, 102, 19],
        [1929, 0, 114, 188],
        [1712, 280, 23, 9],
        [1952, 190, 92, 144],
        [1748, 212, 202, 182],
        [1712, 291, 19, 10],
        [1388, 412, 195, 154],
        [1712, 267, 22, 11],
        [1737, 277, 9, 7],
        [1736, 267, 8, 8],
        [1712, 313, 11, 11],
        [1712, 230, 34, 35],
      ],
    },
  ];

  (lib.AnMovieClip = function () {
    this.actionFrames = [];
    this.ignorePause = false;
    this.gotoAndPlay = function (positionOrLabel) {
      cjs.MovieClip.prototype.gotoAndPlay.call(this, positionOrLabel);
    };
    this.play = function () {
      cjs.MovieClip.prototype.play.call(this);
    };
    this.gotoAndStop = function (positionOrLabel) {
      cjs.MovieClip.prototype.gotoAndStop.call(this, positionOrLabel);
    };
    this.stop = function () {
      cjs.MovieClip.prototype.stop.call(this);
    };
  }).prototype = p = new cjs.MovieClip();
  // symbols:

  (lib.Group = function () {
    this.initialize(ss['confeti_atlas_1']);
    this.gotoAndStop(0);
  }).prototype = p = new cjs.Sprite();

  (lib.Group_1 = function () {
    this.initialize(ss['confeti_atlas_1']);
    this.gotoAndStop(1);
  }).prototype = p = new cjs.Sprite();

  (lib.Group_2 = function () {
    this.initialize(ss['confeti_atlas_1']);
    this.gotoAndStop(2);
  }).prototype = p = new cjs.Sprite();

  (lib.Group_3 = function () {
    this.initialize(ss['confeti_atlas_1']);
    this.gotoAndStop(3);
  }).prototype = p = new cjs.Sprite();

  (lib.Group_4 = function () {
    this.initialize(ss['confeti_atlas_1']);
    this.gotoAndStop(4);
  }).prototype = p = new cjs.Sprite();

  (lib.Group_5 = function () {
    this.initialize(ss['confeti_atlas_1']);
    this.gotoAndStop(5);
  }).prototype = p = new cjs.Sprite();

  (lib.Group_6 = function () {
    this.initialize(ss['confeti_atlas_1']);
    this.gotoAndStop(6);
  }).prototype = p = new cjs.Sprite();

  (lib.Group_7 = function () {
    this.initialize(ss['confeti_atlas_1']);
    this.gotoAndStop(7);
  }).prototype = p = new cjs.Sprite();

  (lib.Group_8 = function () {
    this.initialize(ss['confeti_atlas_1']);
    this.gotoAndStop(8);
  }).prototype = p = new cjs.Sprite();

  (lib.Group_9 = function () {
    this.initialize(ss['confeti_atlas_1']);
    this.gotoAndStop(9);
  }).prototype = p = new cjs.Sprite();

  (lib.Group_10 = function () {
    this.initialize(ss['confeti_atlas_1']);
    this.gotoAndStop(10);
  }).prototype = p = new cjs.Sprite();

  (lib.Group_11 = function () {
    this.initialize(ss['confeti_atlas_1']);
    this.gotoAndStop(11);
  }).prototype = p = new cjs.Sprite();

  (lib.Group_12 = function () {
    this.initialize(ss['confeti_atlas_1']);
    this.gotoAndStop(12);
  }).prototype = p = new cjs.Sprite();

  (lib.Group_13 = function () {
    this.initialize(ss['confeti_atlas_1']);
    this.gotoAndStop(13);
  }).prototype = p = new cjs.Sprite();

  (lib.Group_14 = function () {
    this.initialize(ss['confeti_atlas_1']);
    this.gotoAndStop(14);
  }).prototype = p = new cjs.Sprite();

  (lib.Group_15 = function () {
    this.initialize(ss['confeti_atlas_1']);
    this.gotoAndStop(15);
  }).prototype = p = new cjs.Sprite();

  (lib.Group_16 = function () {
    this.initialize(ss['confeti_atlas_1']);
    this.gotoAndStop(16);
  }).prototype = p = new cjs.Sprite();

  (lib.Group_17 = function () {
    this.initialize(ss['confeti_atlas_1']);
    this.gotoAndStop(17);
  }).prototype = p = new cjs.Sprite();

  (lib.Group_18 = function () {
    this.initialize(ss['confeti_atlas_1']);
    this.gotoAndStop(18);
  }).prototype = p = new cjs.Sprite();

  (lib.Group_19 = function () {
    this.initialize(ss['confeti_atlas_1']);
    this.gotoAndStop(19);
  }).prototype = p = new cjs.Sprite();

  (lib.Group_20 = function () {
    this.initialize(ss['confeti_atlas_1']);
    this.gotoAndStop(20);
  }).prototype = p = new cjs.Sprite();

  (lib.Group_21 = function () {
    this.initialize(ss['confeti_atlas_1']);
    this.gotoAndStop(21);
  }).prototype = p = new cjs.Sprite();

  (lib.Group_22 = function () {
    this.initialize(ss['confeti_atlas_1']);
    this.gotoAndStop(22);
  }).prototype = p = new cjs.Sprite();

  (lib.Group_23 = function () {
    this.initialize(ss['confeti_atlas_1']);
    this.gotoAndStop(23);
  }).prototype = p = new cjs.Sprite();

  (lib.Group_24 = function () {
    this.initialize(ss['confeti_atlas_1']);
    this.gotoAndStop(24);
  }).prototype = p = new cjs.Sprite();

  (lib.Group_25 = function () {
    this.initialize(ss['confeti_atlas_1']);
    this.gotoAndStop(25);
  }).prototype = p = new cjs.Sprite();

  (lib.Group_26 = function () {
    this.initialize(ss['confeti_atlas_1']);
    this.gotoAndStop(26);
  }).prototype = p = new cjs.Sprite();

  (lib.Group_1_1 = function () {
    this.initialize(ss['confeti_atlas_1']);
    this.gotoAndStop(27);
  }).prototype = p = new cjs.Sprite();

  (lib.Group_1_2 = function () {
    this.initialize(ss['confeti_atlas_1']);
    this.gotoAndStop(28);
  }).prototype = p = new cjs.Sprite();

  (lib.Group_6_0 = function () {
    this.initialize(ss['confeti_atlas_1']);
    this.gotoAndStop(29);
  }).prototype = p = new cjs.Sprite();

  (lib.Group_7_1 = function () {
    this.initialize(ss['confeti_atlas_1']);
    this.gotoAndStop(30);
  }).prototype = p = new cjs.Sprite();

  (lib.Group_7_2 = function () {
    this.initialize(ss['confeti_atlas_1']);
    this.gotoAndStop(31);
  }).prototype = p = new cjs.Sprite();

  (lib.Group_8_1 = function () {
    this.initialize(ss['confeti_atlas_1']);
    this.gotoAndStop(32);
  }).prototype = p = new cjs.Sprite();

  (lib.Group_8_2 = function () {
    this.initialize(ss['confeti_atlas_1']);
    this.gotoAndStop(33);
  }).prototype = p = new cjs.Sprite();

  (lib.Group_9_1 = function () {
    this.initialize(ss['confeti_atlas_1']);
    this.gotoAndStop(34);
  }).prototype = p = new cjs.Sprite();

  (lib.Image = function () {
    this.initialize(ss['confeti_atlas_1']);
    this.gotoAndStop(35);
  }).prototype = p = new cjs.Sprite();

  (lib.Image_1 = function () {
    this.initialize(ss['confeti_atlas_1']);
    this.gotoAndStop(36);
  }).prototype = p = new cjs.Sprite();

  (lib.Image_2 = function () {
    this.initialize(ss['confeti_atlas_1']);
    this.gotoAndStop(37);
  }).prototype = p = new cjs.Sprite();

  (lib.Image_3 = function () {
    this.initialize(ss['confeti_atlas_1']);
    this.gotoAndStop(38);
  }).prototype = p = new cjs.Sprite();

  (lib.Image_4 = function () {
    this.initialize(ss['confeti_atlas_1']);
    this.gotoAndStop(39);
  }).prototype = p = new cjs.Sprite();

  (lib.Image_5 = function () {
    this.initialize(ss['confeti_atlas_1']);
    this.gotoAndStop(40);
  }).prototype = p = new cjs.Sprite();

  (lib.Image_6 = function () {
    this.initialize(ss['confeti_atlas_1']);
    this.gotoAndStop(41);
  }).prototype = p = new cjs.Sprite();

  (lib.Image_7 = function () {
    this.initialize(ss['confeti_atlas_1']);
    this.gotoAndStop(42);
  }).prototype = p = new cjs.Sprite();

  (lib.Image_0 = function () {
    this.initialize(ss['confeti_atlas_1']);
    this.gotoAndStop(43);
  }).prototype = p = new cjs.Sprite();

  (lib.Image_0_1 = function () {
    this.initialize(ss['confeti_atlas_1']);
    this.gotoAndStop(44);
  }).prototype = p = new cjs.Sprite();

  (lib.Image_0_2 = function () {
    this.initialize(ss['confeti_atlas_1']);
    this.gotoAndStop(45);
  }).prototype = p = new cjs.Sprite();

  (lib.Image_0_3 = function () {
    this.initialize(ss['confeti_atlas_1']);
    this.gotoAndStop(46);
  }).prototype = p = new cjs.Sprite();

  (lib.Image_1_1 = function () {
    this.initialize(ss['confeti_atlas_1']);
    this.gotoAndStop(47);
  }).prototype = p = new cjs.Sprite();

  (lib.Image_1_2 = function () {
    this.initialize(ss['confeti_atlas_1']);
    this.gotoAndStop(48);
  }).prototype = p = new cjs.Sprite();

  (lib.Image_1_3 = function () {
    this.initialize(ss['confeti_atlas_1']);
    this.gotoAndStop(49);
  }).prototype = p = new cjs.Sprite();

  (lib.Image_2_1 = function () {
    this.initialize(ss['confeti_atlas_1']);
    this.gotoAndStop(50);
  }).prototype = p = new cjs.Sprite();

  (lib.Image_3_1 = function () {
    this.initialize(ss['confeti_atlas_1']);
    this.gotoAndStop(51);
  }).prototype = p = new cjs.Sprite();

  (lib.Mesh_30 = function () {
    this.initialize(ss['confeti_atlas_1']);
    this.gotoAndStop(52);
  }).prototype = p = new cjs.Sprite();

  (lib.Mesh_31 = function () {
    this.initialize(ss['confeti_atlas_1']);
    this.gotoAndStop(53);
  }).prototype = p = new cjs.Sprite();

  (lib.Mesh_6 = function () {
    this.initialize(ss['confeti_atlas_1']);
    this.gotoAndStop(54);
  }).prototype = p = new cjs.Sprite();
  // helper functions:

  function mc_symbol_clone() {
    var clone = this._cloneProps(new this.constructor(this.mode, this.startPosition, this.loop, this.reversed));
    clone.gotoAndStop(this.currentFrame);
    clone.paused = this.paused;
    clone.framerate = this.framerate;
    return clone;
  }

  function getMCSymbolPrototype(symbol, nominalBounds, frameBounds) {
    var prototype = cjs.extend(symbol, cjs.MovieClip);
    prototype.clone = mc_symbol_clone;
    prototype.nominalBounds = nominalBounds;
    prototype.frameBounds = frameBounds;
    return prototype;
  }

  (lib.Interpolación35 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_1
    this.instance = new lib.Mesh_31();
    this.instance.setTransform(-26, -26, 4.7307, 4.7307);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-26, -26, 52.1, 52.1);

  (lib.Interpolación34 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_1
    this.instance = new lib.Mesh_30();
    this.instance.setTransform(-18.9, -18.9, 4.7307, 4.7307);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-18.9, -18.9, 37.9, 37.9);

  (lib.ClipGroup_0 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask = new cjs.Shape();
    mask._off = true;
    mask.graphics.p('AiRChIAAlBIEjAAIAAFBg');
    mask.setTransform(14.6, 16.15);

    // Capa_3
    this.instance = new lib.Image();
    this.instance.setTransform(0, 0, 0.48, 0.48);

    var maskedShapeInstanceList = [this.instance];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_0, new cjs.Rectangle(0, 0.1, 29.2, 32.1), null);

  (lib.ClipGroup_5 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask = new cjs.Shape();
    mask._off = true;
    mask.graphics.p(
      'AndNZQgngzAHiEQAFhfAQgzQAFgTAqgPQAWgIAjgIQgTg+glhqQgmhqgIgbQgOgvAgg8QAdg2BIhJQBDhDBCgdQA+gaBQAAQBCABBHgIQAkgEAXgEQgbhPgvicQgfhoAihGQAOgdBShbQA1g6AsgcQAfgUAwgPIA1gPQAkgKAbAAQBFAAAMBCQANBGgGBmQgEBRgKAlQgFAWgpAQIgpAMIAEAZQAEAgAAAmQgCB2gsB5QguB9hIBEQgUAThwBNQg4AnhVARIhLAKIADAQQAFAaAEAuQAGA9glB6QgoCGg8BUQg8BWhbAtQgxAYgeAAQgWAAgKgNg'
    );
    mask.setTransform(51.0802, 87.0327);

    // Capa_3
    this.instance = new lib.Group_26();
    this.instance.setTransform(39.3, 63.3);

    var maskedShapeInstanceList = [this.instance];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_5, new cjs.Rectangle(39.3, 63.3, 40, 13), null);

  (lib.ClipGroup_4 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask = new cjs.Shape();
    mask._off = true;
    mask.graphics.p('AnPFwIAArfIOfAAIAALfg');
    mask.setTransform(46.8, 36.925);

    // Capa_3
    this.instance = new lib.Image_1_3();
    this.instance.setTransform(0, 0, 0.48, 0.48);

    var maskedShapeInstanceList = [this.instance];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_4, new cjs.Rectangle(0.4, 0.1, 92.8, 73.7), null);

  (lib.ClipGroup_1 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask = new cjs.Shape();
    mask._off = true;
    mask.graphics.p('AjXFYIAAqvIGvAAIAAKvg');
    mask.setTransform(22.1, 34.65);

    // Capa_3
    this.instance = new lib.Image_0_3();
    this.instance.setTransform(0, 0, 0.48, 0.48);

    var maskedShapeInstanceList = [this.instance];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_1, new cjs.Rectangle(0.5, 0.3, 43.3, 68.8), null);

  (lib.ClipGroup_0_1 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_1 = new cjs.Shape();
    mask_1._off = true;
    mask_1.graphics.p('Aj5DOIAAmbIHzAAIAAGbg');
    mask_1.setTransform(25.075, 21.025);

    // Capa_3
    this.instance_1 = new lib.Image_7();
    this.instance_1.setTransform(0, 0, 0.48, 0.48);

    var maskedShapeInstanceList = [this.instance_1];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_1;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_0_1, new cjs.Rectangle(0.1, 0.4, 50, 41.300000000000004), null);

  (lib.Group_0 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_1
    this.instance = new lib.Group_8_2();

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.Group_0, new cjs.Rectangle(0, 0, 23, 8), null);

  (lib.ClipGroup_18 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask = new cjs.Shape();
    mask._off = true;
    mask.graphics.p('AgTAPIAAgdIAnAAIAAAdg');
    mask.setTransform(2.075, 1.725);

    // Capa_3
    this.instance = new lib.Image_3_1();
    this.instance.setTransform(0, 0, 0.48, 0.48);

    var maskedShapeInstanceList = [this.instance];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_18, new cjs.Rectangle(0.1, 0.2, 3.9999999999999996, 3.0999999999999996), null);

  (lib.ClipGroup_16 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask = new cjs.Shape();
    mask._off = true;
    mask.graphics.p('AgwAYIAAgvIBiAAIAAAvg');
    mask.setTransform(5.4, 2.65);

    // Capa_3
    this.instance = new lib.Image_2_1();
    this.instance.setTransform(0, 0, 0.48, 0.48);

    var maskedShapeInstanceList = [this.instance];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_16, new cjs.Rectangle(0.5, 0.3, 9.9, 4.8), null);

  (lib.ClipGroup_15 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask = new cjs.Shape();
    mask._off = true;
    mask.graphics.p('AgqAXIAAgtIBVAAIAAAtg');
    mask.setTransform(4.625, 2.45);

    // Capa_3
    this.instance = new lib.Image_1_2();
    this.instance.setTransform(0, 0, 0.48, 0.48);

    var maskedShapeInstanceList = [this.instance];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_15, new cjs.Rectangle(0.4, 0.2, 8.5, 4.6), null);

  (lib.ClipGroup_14 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask = new cjs.Shape();
    mask._off = true;
    mask.graphics.p('Ag1AVIAAgpIBrAAIAAApg');
    mask.setTransform(5.575, 2.125);

    // Capa_3
    this.instance = new lib.Image_0_2();
    this.instance.setTransform(0, 0, 0.48, 0.48);

    var maskedShapeInstanceList = [this.instance];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_14, new cjs.Rectangle(0.2, 0, 10.8, 4.3), null);

  (lib.ClipGroup_13 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask = new cjs.Shape();
    mask._off = true;
    mask.graphics.p('AhrBsIAAjXIDXAAIAADXg');
    mask.setTransform(11.3, 10.8);

    // Capa_3
    this.instance = new lib.Image_6();
    this.instance.setTransform(0, 0, 0.48, 0.48);

    var maskedShapeInstanceList = [this.instance];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_13, new cjs.Rectangle(0.5, 0, 21.7, 21.6), null);

  (lib.ClipGroup_12 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask = new cjs.Shape();
    mask._off = true;
    mask.graphics.p('AD1AoQg9g1hRgaQhxglh6AYQg3ALhQAlIhEAeIgTALQgMAHgIABQAAgkBEg2QBCg2BUgiQBFgbBHgHQBKgIBEAQQBHARA8ArQA9AsAhA9QAaAvgCApQgBAXgKAVQgLAWgSALQgYhKhCg5g');
    mask.setTransform(47.4792, 39.3444);

    // Capa_3
    this.shape = new cjs.Shape();
    this.shape.graphics
      .lf(['#000000', '#040301', '#0F0E02', '#231F07', '#3E370F', '#433B11'], [0, 0.243, 0.486, 0.725, 0.965, 1], 7.3, 23.7, -3.1, -10.1)
      .s()
      .p('AnaiRIMRjyICkIVIsRDyg');
    this.shape.setTransform(47.525, 38.8);

    var maskedShapeInstanceList = [this.shape];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask;
    }

    this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_12, new cjs.Rectangle(10, 22.3, 75, 34.2), null);

  (lib.ClipGroup_10 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask = new cjs.Shape();
    mask._off = true;
    mask.graphics.p('Ah9ByQgNgQADgiQACgaAIgaQASgzAugkQAugkBBgKQAqgGAQADQAJABAHAJQAIAJAFACQgXgLgbAAQgaAAgaAKQgoARgqAuQgXAagSAlIgFAKQgWBhASgEIgHABQgMAAgJgMg');
    mask.setTransform(21.7292, 20.7293);

    // Capa_3
    this.shape = new cjs.Shape();
    this.shape.graphics
      .lf(['#433B11', '#3E370F', '#231F07', '#0F0E02', '#040301', '#000000'], [0, 0.035, 0.275, 0.514, 0.757, 1], 2.5, 7.9, -2.2, -7.2)
      .s()
      .p('AjUhpIFJhmIBgE5IlIBmg');
    this.shape.setTransform(21.325, 20.775);

    var maskedShapeInstanceList = [this.shape];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask;
    }

    this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_10, new cjs.Rectangle(8.1, 8.2, 27.299999999999997, 25.2), null);

  (lib.ClipGroup_8 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask = new cjs.Shape();
    mask._off = true;
    mask.graphics.p('ABqDSQBZhiARh7QAKhRgkgoQgVgYgpgLQhzggh5BXQhlBHg4BsQArhXAbgnQAvhFBDgoQBXg3BbgEQBvgGAvBTQAcAxgNBTQgMBHggA7QggA6grAkQgPANgYAIIgJACQgKAAARgTg');
    mask.setTransform(53.0436, 54.1799);

    // Capa_3
    this.shape = new cjs.Shape();
    this.shape.graphics
      .lf(['#433B11', '#3E370F', '#231F07', '#0F0E02', '#040301', '#000000'], [0, 0.035, 0.275, 0.514, 0.757, 1], -16.2, -13.4, 24.6, 20.3)
      .s()
      .p('AoRAxIHepBIJFHgIneJBg');
    this.shape.setTransform(52.95, 52.925);

    var maskedShapeInstanceList = [this.shape];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask;
    }

    this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_8, new cjs.Rectangle(26.1, 31.3, 53.9, 45.8), null);

  (lib.ClipGroup_5_1 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_1 = new cjs.Shape();
    mask_1._off = true;
    mask_1.graphics.p('AhsBCQg5gNgmgmQBOAaBTgKQBTgLBFgrIAhgWQAUgOAOgIQATgKAGAPQAGAOgOANQgIAHgSALIgbARQhGA0hHAPQgaAFgaAAQgdAAgbgGg');
    mask_1.setTransform(23.9776, 17.3464);

    // Capa_3
    this.shape = new cjs.Shape();
    this.shape.graphics
      .lf(['#433B11', '#3E370F', '#231F07', '#0F0E02', '#040301', '#000000'], [0, 0.035, 0.275, 0.514, 0.757, 1], -22.9, 5.3, 16.9, -3.9)
      .s()
      .p('AjzhJIGthjIA6D1ImuBkg');
    this.shape.setTransform(24.375, 17.325);

    var maskedShapeInstanceList = [this.shape];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_1;
    }

    this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_5_1, new cjs.Rectangle(3.6, 10.2, 40.8, 14.400000000000002), null);

  (lib.ClipGroup_3 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask = new cjs.Shape();
    mask._off = true;
    mask.graphics.p('AhHBBQgjgPgXgiQgagkANgiQAEgKAIgGQAIgGAJAAQANABAJAOQAGAIAIAVQAQApAhASQAUAMAdADQAdACAjgIQAWgFAogOQg3AngxANQgcAIgYAAQghAAgdgMg');
    mask.setTransform(20.5333, 16.4359);

    // Capa_3
    this.shape = new cjs.Shape();
    this.shape.graphics
      .lf(['#433B11', '#3E370F', '#231F07', '#0F0E02', '#040301', '#000000'], [0, 0.035, 0.275, 0.514, 0.757, 1], 2.1, 6.9, -2.3, -7.6)
      .s()
      .p('AjGhBIFFhkIBHDmIlEBlg');
    this.shape.setTransform(19.85, 16.55);

    var maskedShapeInstanceList = [this.shape];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask;
    }

    this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_3, new cjs.Rectangle(5.8, 8.8, 29.499999999999996, 15.399999999999999), null);

  (lib.ClipGroup_1_1 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_1 = new cjs.Shape();
    mask_1._off = true;
    mask_1.graphics.p(
      'AlJHpQgKgKAFgIQADgDAHgBIBHgKQB0gUBphSQBchJBIhvQBSiAAKhyQAJhjgtheQgrhahShAQhAgyhSgIQgIgBgrACQghACgPgGQAcgUA0gFQAngEAuAFQBeAKBNA0QBHAwAvBOQAuBLANBXQAVCDg0CDQgvB5hiBmQgpAqgYAVQgoAjghARQh1A8ifAAQgyAAgUgSg'
    );
    mask_1.setTransform(62.5473, 70.6361);

    // Capa_3
    this.shape = new cjs.Shape();
    this.shape.graphics
      .lf(['#433B11', '#3E370F', '#231F07', '#0F0E02', '#040301', '#000000'], [0, 0.035, 0.275, 0.514, 0.757, 1], 11.7, 37.8, -11.1, -36.2)
      .s()
      .p('Ap7mjIOckdIFaRlIuaEcg');
    this.shape.setTransform(63.55, 70.475);

    var maskedShapeInstanceList = [this.shape];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_1;
    }

    this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_1_1, new cjs.Rectangle(28.9, 19.9, 67.4, 101.5), null);

  (lib.Group_6_1 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_1
    this.instance = new lib.Group_6_0();

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.Group_6_1, new cjs.Rectangle(0, 0, 134, 108), null);

  (lib.ClipGroup_8_1 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_1 = new cjs.Shape();
    mask_1._off = true;
    mask_1.graphics.p(
      'AAZLcQiagxiShsQiChhh1iNQh8iVhTilQhXisgki0QgrjWAuiiQANgtAWgQQATgNAbADQAZADAUAQQAnAfAVBCQAMAlASBMQAlB4A3B1QB0D1CtCuQChCjDLBcQDVBgDaAAQAfAABZgGQBLgEAsACQA7ABAUAbQAPAUgDAkQgEAxghAtQgeAqguAbQgpAZg2APQgpALg9AKQh1AShqAAQirAAiQgug'
    );
    mask_1.setTransform(161.8112, 160.7081);

    // Capa_3
    this.shape_1 = new cjs.Shape();
    this.shape_1.graphics
      .lf(['#433B11', '#3E370F', '#231F07', '#0F0E02', '#040301', '#000000'], [0, 0.035, 0.275, 0.514, 0.757, 1], 65.7, 95.7, 19.1, 27.8)
      .s()
      .p('A5NkZIeI0uIUTdhI+JUtg');
    this.shape_1.setTransform(161.375, 160.75);

    var maskedShapeInstanceList = [this.shape_1];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_1;
    }

    this.timeline.addTween(cjs.Tween.get(this.shape_1).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_8_1, new cjs.Rectangle(74.4, 83, 174.79999999999998, 155.5), null);

  (lib.ClipGroup_6 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask = new cjs.Shape();
    mask._off = true;
    mask.graphics.p('Al0BwQCYiHDFhBQDOhEC+AjIk1BTQgmAKgVAKQgdAPgOAXQBeAXAvANQBOAXA7AcQhHALhEgPQhQgRhzAQQiEAYhCAHIgXABQgpAAgQgWg');
    mask.setTransform(42.15, 28.8404);

    // Capa_3
    this.shape = new cjs.Shape();
    this.shape.graphics
      .lf(['#433B11', '#3E370F', '#231F07', '#0F0E02', '#040301', '#000000'], [0, 0.035, 0.275, 0.514, 0.757, 1], -30.2, -4.9, 21.7, 3.6)
      .s()
      .p('AmlCRIBFmgIMGB/IhFGfg');
    this.shape.setTransform(42.15, 27.15);

    var maskedShapeInstanceList = [this.shape];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask;
    }

    this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_6, new cjs.Rectangle(4.9, 15.5, 74.6, 26.799999999999997), null);

  (lib.ClipGroup_4_1 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_1 = new cjs.Shape();
    mask_1._off = true;
    mask_1.graphics.p('AgcDEQgggGgfgLQhJgZg8gzQg9gzglhDQBmBVBiAqQCAA2BlgiQAwgQAoglQAmgiAagvQArhRAIh3QAXBtgTBXQgaB5hlA2QhAAihOAAQgjAAgmgHg');
    mask_1.setTransform(52.2123, 50.7016);

    // Capa_3
    this.shape = new cjs.Shape();
    this.shape.graphics
      .lf(['#433B11', '#3E370F', '#231F07', '#0F0E02', '#040301', '#000000'], [0, 0.035, 0.275, 0.514, 0.757, 1], 15.2, 22.1, 5.4, 7.8)
      .s()
      .p('AoMhLIKBm6IGYJSIqBG5g');
    this.shape.setTransform(52.475, 51.75);

    var maskedShapeInstanceList = [this.shape];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_1;
    }

    this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_4_1, new cjs.Rectangle(20, 30.4, 64.5, 40.6), null);

  (lib.ClipGroup_2 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask = new cjs.Shape();
    mask._off = true;
    mask.graphics.p('ACANtQgghlhBh6QgnhLgPhRQgLhAAAhmQAAl1hYl1QhZl7imlDQAPAcAvAoIBKA/QBSBSA8BCQCPCeBSCMQBjCpA+C1QBAC7ATC9QAXDggoDcQgpDhhoDBQgWAogRAcQgBhzgoh9g');
    mask.setTransform(100.8948, 133.9125);

    // Capa_3
    this.shape = new cjs.Shape();
    this.shape.graphics
      .lf(['#433B11', '#3E370F', '#231F07', '#0F0E02', '#040301', '#000000'], [0, 0.035, 0.275, 0.514, 0.757, 1], 29.8, 96.9, -24.9, -80.7)
      .s()
      .p('Av6ucIU9mdMAK4AjWI08Gdg');
    this.shape.setTransform(101.925, 133.8);

    var maskedShapeInstanceList = [this.shape];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask;
    }

    this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_2, new cjs.Rectangle(63.1, 22.3, 75.70000000000002, 223.29999999999998), null);

  (lib.ClipGroup_5_2 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_2 = new cjs.Shape();
    mask_2._off = true;
    mask_2.graphics.p(
      'ApqUoQgfAAgYg0QgQgjgUhOQgOg3ALhRQAOhNABgNQAAgQg5iyQhJjjgShBQgdhnBzifQBChaCCh9QB8h5DugQQB5gJBWgSQgSg4gYhMQgxibgWhrQgYhzBPiDQAzhVCFiNQBbhfBUgzQAvgbBLgbQAngOBbgOQB3gTAVgFQATgDANgBIACAAQArABAXAwQAaA0ASCLQAJBDAAA2IAAADQgBBlgZBGQgUA7gfAWQgdAVh0ARQhSALhbAFQBGDGgtDMQgYBphLCLQhRCShnB0QhdBqijAqQhwAchwgGQAXB3AABVQAABugjB6QgiB4hiCOQhUB4hEA4QgWATgSgBg'
    );
    mask_2.setTransform(85.309, 132.05);

    // Capa_3
    this.instance_1 = new lib.Group_23();
    this.instance_1.setTransform(0, 66.1);

    var maskedShapeInstanceList = [this.instance_1];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_2;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_5_2, new cjs.Rectangle(0.1, 66.1, 135.9, 125), null);

  (lib.ClipGroup_4_2 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_2 = new cjs.Shape();
    mask_2._off = true;
    mask_2.graphics.p('AniGzIAAtlIPFAAIAANlg');
    mask_2.setTransform(48.675, 43.85);

    // Capa_3
    this.instance_1 = new lib.Image_1_1();
    this.instance_1.setTransform(0, 0, 0.48, 0.48);

    var maskedShapeInstanceList = [this.instance_1];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_2;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_4_2, new cjs.Rectangle(0.4, 0.4, 96.6, 86.89999999999999), null);

  (lib.ClipGroup_1_2 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_2 = new cjs.Shape();
    mask_2._off = true;
    mask_2.graphics.p('AkQHAIAAt/IIhAAIAAN/g');
    mask_2.setTransform(27.3, 45.05);

    // Capa_3
    this.instance_1 = new lib.Image_0_1();
    this.instance_1.setTransform(0, 0, 0.48, 0.48);

    var maskedShapeInstanceList = [this.instance_1];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_2;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_1_2, new cjs.Rectangle(0, 0.3, 54.6, 89.5), null);

  (lib.ClipGroup_0_2 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_2 = new cjs.Shape();
    mask_2._off = true;
    mask_2.graphics.p('AkdDmIAAnLII7AAIAAHLg');
    mask_2.setTransform(28.725, 23.175);

    // Capa_3
    this.instance_2 = new lib.Image_5();
    this.instance_2.setTransform(0, 0, 0.48, 0.48);

    var maskedShapeInstanceList = [this.instance_2];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_2;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_2).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_0_2, new cjs.Rectangle(0.2, 0.2, 57.099999999999994, 46), null);

  (lib.ClipGroup_0_3 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_3 = new cjs.Shape();
    mask_3._off = true;
    mask_3.graphics.p('A12WoMAAAgtPMArtAAAMAAAAtPg');
    mask_3.setTransform(140.35, 145.075);

    // Capa_3
    this.instance_3 = new lib.Image_4();
    this.instance_3.setTransform(0, 0, 0.48, 0.48);

    var maskedShapeInstanceList = [this.instance_3];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_3;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_3).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_0_3, new cjs.Rectangle(0.5, 0.3, 279.8, 289.59999999999997), null);

  (lib.ClipGroup_0_4 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_4 = new cjs.Shape();
    mask_4._off = true;
    mask_4.graphics.p('AqvKwIAA1fIVfAAIAAVfg');
    mask_4.setTransform(68.775, 68.775);

    // Capa_3
    this.instance_4 = new lib.Group_22();

    var maskedShapeInstanceList = [this.instance_4];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_4;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_4).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_0_4, new cjs.Rectangle(0, 0, 137.6, 137.6), null);

  (lib.ClipGroup_0_5 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_5 = new cjs.Shape();
    mask_5._off = true;
    mask_5.graphics.p('AqvKwIAA1fIVfAAIAAVfg');
    mask_5.setTransform(68.775, 68.775);

    // Capa_3
    this.instance_5 = new lib.Group_21();

    var maskedShapeInstanceList = [this.instance_5];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_5;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_5).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_0_5, new cjs.Rectangle(0, 0, 137.6, 137.6), null);

  (lib.ClipGroup_0_6 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_6 = new cjs.Shape();
    mask_6._off = true;
    mask_6.graphics.p('AqvKwIAA1fIVfAAIAAVfg');
    mask_6.setTransform(68.775, 68.775);

    // Capa_3
    this.instance_6 = new lib.Group_20();

    var maskedShapeInstanceList = [this.instance_6];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_6;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_6).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_0_6, new cjs.Rectangle(0, 0, 137.6, 137.6), null);

  (lib.ClipGroup_0_7 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_7 = new cjs.Shape();
    mask_7._off = true;
    mask_7.graphics.p('AqvKwIAA1fIVfAAIAAVfg');
    mask_7.setTransform(68.775, 68.775);

    // Capa_3
    this.instance_7 = new lib.Group_19();

    var maskedShapeInstanceList = [this.instance_7];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_7;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_7).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_0_7, new cjs.Rectangle(0, 0, 137.6, 137.6), null);

  (lib.ClipGroup_0_8 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_8 = new cjs.Shape();
    mask_8._off = true;
    mask_8.graphics.p('AqvKwIAA1fIVfAAIAAVfg');
    mask_8.setTransform(68.775, 68.775);

    // Capa_3
    this.instance_8 = new lib.Group_18();

    var maskedShapeInstanceList = [this.instance_8];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_8;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_8).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_0_8, new cjs.Rectangle(0, 0, 137.6, 137.6), null);

  (lib.ClipGroup_0_9 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_9 = new cjs.Shape();
    mask_9._off = true;
    mask_9.graphics.p('AqvKwIAA1fIVfAAIAAVfg');
    mask_9.setTransform(68.775, 68.775);

    // Capa_3
    this.instance_9 = new lib.Group_17();

    var maskedShapeInstanceList = [this.instance_9];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_9;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_9).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_0_9, new cjs.Rectangle(0, 0, 137.6, 137.6), null);

  (lib.ClipGroup_0_10 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_10 = new cjs.Shape();
    mask_10._off = true;
    mask_10.graphics.p('AqvKwIAA1fIVfAAIAAVfg');
    mask_10.setTransform(68.775, 68.775);

    // Capa_3
    this.instance_10 = new lib.Group_16();

    var maskedShapeInstanceList = [this.instance_10];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_10;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_10).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_0_10, new cjs.Rectangle(0, 0, 137.6, 137.6), null);

  (lib.ClipGroup_0_11 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_11 = new cjs.Shape();
    mask_11._off = true;
    mask_11.graphics.p('AqvKwIAA1fIVfAAIAAVfg');
    mask_11.setTransform(68.775, 68.775);

    // Capa_3
    this.instance_11 = new lib.Group_15();

    var maskedShapeInstanceList = [this.instance_11];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_11;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_11).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_0_11, new cjs.Rectangle(0, 0, 137.6, 137.6), null);

  (lib.ClipGroup_0_12 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_12 = new cjs.Shape();
    mask_12._off = true;
    mask_12.graphics.p('At7QXMAAAggtIb3AAMAAAAgtg');
    mask_12.setTransform(89.675, 104.725);

    // Capa_3
    this.instance_12 = new lib.Group_14();

    var maskedShapeInstanceList = [this.instance_12];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_12;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_12).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_0_12, new cjs.Rectangle(0.5, 0, 178.4, 209.5), null);

  (lib.Group_0_1 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_1
    this.instance_1 = new lib.Group_1_1();

    this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.Group_0_1, new cjs.Rectangle(0, 0, 63, 27), null);

  (lib.ClipGroup_2_1 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_1 = new cjs.Shape();
    mask_1._off = true;
    mask_1.graphics.p('AtmNOIAA6cIbNAAIAAacg');
    mask_1.setTransform(87.35, 84.85);

    // Capa_3
    this.instance = new lib.Group_13();

    var maskedShapeInstanceList = [this.instance];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_1;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_2_1, new cjs.Rectangle(0.3, 0.2, 174.1, 169.3), null);

  (lib.ClipGroup_0_13 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_13 = new cjs.Shape();
    mask_13._off = true;
    mask_13.graphics.p('AhzBwIAAjgIDnAAIAADgg');
    mask_13.setTransform(12.025, 11.65);

    // Capa_3
    this.instance_13 = new lib.Image_3();
    this.instance_13.setTransform(0, 0, 0.48, 0.48);

    var maskedShapeInstanceList = [this.instance_13];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_13;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_13).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_0_13, new cjs.Rectangle(0.5, 0.4, 23.1, 22.5), null);

  (lib.ClipGroup_0_14 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_14 = new cjs.Shape();
    mask_14._off = true;
    mask_14.graphics.p('AseOvIAA9dIY8AAIAAddg');
    mask_14.setTransform(79.85, 94.35);

    // Capa_3
    this.instance_14 = new lib.Group_12();

    var maskedShapeInstanceList = [this.instance_14];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_14;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_14).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_0_14, new cjs.Rectangle(0, 0.1, 159.7, 188.6), null);

  (lib.ClipGroup_0_15 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_15 = new cjs.Shape();
    mask_15._off = true;
    mask_15.graphics.p('AtYM5IAA5xIaxAAIAAZxg');
    mask_15.setTransform(85.725, 82.55);

    // Capa_3
    this.instance_15 = new lib.Group_11();

    var maskedShapeInstanceList = [this.instance_15];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_15;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_15).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_0_15, new cjs.Rectangle(0.1, 0.1, 171.3, 164.9), null);

  (lib.ClipGroup_2_2 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_2 = new cjs.Shape();
    mask_2._off = true;
    mask_2.graphics.p('AjBDOIAAmbIGDAAIAAGbg');
    mask_2.setTransform(19.375, 20.8);

    // Capa_3
    this.instance_1 = new lib.Image_2();
    this.instance_1.setTransform(0, 0, 0.48, 0.48);

    var maskedShapeInstanceList = [this.instance_1];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_2;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_2_2, new cjs.Rectangle(0, 0.2, 38.8, 41.199999999999996), null);

  (lib.ClipGroup_1_3 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_3 = new cjs.Shape();
    mask_3._off = true;
    mask_3.graphics.p('AuMODIAA8FIcZAAIAAcFg');
    mask_3.setTransform(91.15, 89.95);

    // Capa_3
    this.instance_2 = new lib.Group_10();

    var maskedShapeInstanceList = [this.instance_2];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_3;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_2).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_1_3, new cjs.Rectangle(0.3, 0.1, 181.79999999999998, 179.70000000000002), null);

  (lib.ClipGroup_0_16 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_16 = new cjs.Shape();
    mask_16._off = true;
    mask_16.graphics.p('A2dYQMAAAgwfMAs7AAAMAAAAwfg');
    mask_16.setTransform(143.85, 155.325);

    // Capa_3
    this.instance_16 = new lib.Group_9();

    var maskedShapeInstanceList = [this.instance_16];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_16;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_16).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_0_16, new cjs.Rectangle(0.1, 0.1, 287.59999999999997, 310.5), null);

  (lib.ClipGroup_0_17 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_17 = new cjs.Shape();
    mask_17._off = true;
    mask_17.graphics.p('Egb6AoFMAAAhQJMA31AAAMAAABQJg');
    mask_17.setTransform(178.7, 256.575);

    // Capa_3
    this.instance_17 = new lib.Group_8();

    var maskedShapeInstanceList = [this.instance_17];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_17;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_17).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_0_17, new cjs.Rectangle(0, 0.1, 357.4, 513), null);

  (lib.ClipGroup_0_18 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_18 = new cjs.Shape();
    mask_18._off = true;
    mask_18.graphics.p('ArkPvIAA/dIXJAAIAAfdg');
    mask_18.setTransform(74.2, 100.725);

    // Capa_3
    this.instance_18 = new lib.Group_7();

    var maskedShapeInstanceList = [this.instance_18];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_18;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_18).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_0_18, new cjs.Rectangle(0.1, 0.1, 148.3, 201.3), null);

  (lib.ClipGroup_6_1 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_1 = new cjs.Shape();
    mask_1._off = true;
    mask_1.graphics.p('AjyAqIAAhTIHlAAIAABTg');
    mask_1.setTransform(24.45, 4.6);

    // Capa_3
    this.instance = new lib.Image_0();
    this.instance.setTransform(0, 0, 0.48, 0.48);

    var maskedShapeInstanceList = [this.instance];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_1;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_6_1, new cjs.Rectangle(0.2, 0.4, 48.599999999999994, 8.4), null);

  (lib.ClipGroup_2_3 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_3 = new cjs.Shape();
    mask_3._off = true;
    mask_3.graphics.p('ArnPxIAA/hIXPAAIAAfhg');
    mask_3.setTransform(74.475, 101.65);

    // Capa_3
    this.instance_2 = new lib.Group_6();

    var maskedShapeInstanceList = [this.instance_2];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_3;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_2).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_2_3, new cjs.Rectangle(0.1, 0.8, 148.8, 201.79999999999998), null);

  (lib.ClipGroup_0_19 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_19 = new cjs.Shape();
    mask_19._off = true;
    mask_19.graphics.p('Ai5BDIAAiGIFzAAIAACGg');
    mask_19.setTransform(18.625, 7.05);

    // Capa_3
    this.instance_19 = new lib.Image_1();
    this.instance_19.setTransform(0, 0, 0.48, 0.48);

    var maskedShapeInstanceList = [this.instance_19];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_19;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_19).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_0_19, new cjs.Rectangle(0.1, 0.3, 37.1, 13.5), null);

  (lib.ClipGroup_0_20 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_20 = new cjs.Shape();
    mask_20._off = true;
    mask_20.graphics.p('AsHLWIAA2rIYPAAIAAWrg');
    mask_20.setTransform(77.675, 72.65);

    // Capa_3
    this.instance_20 = new lib.Group_5();

    var maskedShapeInstanceList = [this.instance_20];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_20;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_20).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_0_20, new cjs.Rectangle(0.1, 0.1, 155.20000000000002, 145.20000000000002), null);

  (lib.ClipGroup_0_21 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_21 = new cjs.Shape();
    mask_21._off = true;
    mask_21.graphics.p('AxCRwMAAAgjfMAiFAAAMAAAAjfg');
    mask_21.setTransform(109.2, 113.625);

    // Capa_3
    this.instance_21 = new lib.Group_4();

    var maskedShapeInstanceList = [this.instance_21];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_21;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_21).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_0_21, new cjs.Rectangle(0.1, 0.1, 218.3, 227.1), null);

  (lib.ClipGroup_0_22 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_22 = new cjs.Shape();
    mask_22._off = true;
    mask_22.graphics.p('AnMFYIAAqvIOZAAIAAKvg');
    mask_22.setTransform(46.125, 34.475);

    // Capa_3
    this.instance_22 = new lib.Group_3();

    var maskedShapeInstanceList = [this.instance_22];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_22;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_22).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_0_22, new cjs.Rectangle(0, 0.1, 92.3, 68.80000000000001), null);

  (lib.ClipGroup_0_23 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_23 = new cjs.Shape();
    mask_23._off = true;
    mask_23.graphics.p('Ao9I1IAAxpIR7AAIAARpg');
    mask_23.setTransform(57.4, 56.575);

    // Capa_3
    this.instance_23 = new lib.Group_2();

    var maskedShapeInstanceList = [this.instance_23];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_23;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_23).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_0_23, new cjs.Rectangle(0, 0.1, 114.8, 113), null);

  (lib.ClipGroup_0_24 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_24 = new cjs.Shape();
    mask_24._off = true;
    mask_24.graphics.p('AoDJ4IAAzvIQHAAIAATvg');
    mask_24.setTransform(51.725, 63.55);

    // Capa_3
    this.instance_24 = new lib.Group_1();

    var maskedShapeInstanceList = [this.instance_24];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_24;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_24).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_0_24, new cjs.Rectangle(0.2, 0.4, 103.1, 126.3), null);

  (lib.ClipGroup_0_25 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_25 = new cjs.Shape();
    mask_25._off = true;
    mask_25.graphics.p('AmkIEIAAwHINJAAIAAQHg');
    mask_25.setTransform(42.125, 51.7);

    // Capa_3
    this.instance_25 = new lib.Group();

    var maskedShapeInstanceList = [this.instance_25];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_25;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_25).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_0_25, new cjs.Rectangle(0.1, 0.1, 84.10000000000001, 103.30000000000001), null);

  (lib.ClipGroup = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask = new cjs.Shape();
    mask._off = true;
    mask.graphics.p(
      'AAYCeIgVgRQgVgRgLgGQgNgGgGgBQgJgDgIAAIgtABQgJAAgHgCIgGgBQgFgCgDgCQgDgCAAgCIgCgEIAAgFQAAgIAIgWIAGgWQADgPABgWIgBgPQgBgIgEgRIgKgiIgBgCIAAgMIAAgDIACgDIAEgFIAGgCIADgBIAqAAQAcgCAOgEIAFgCQAGgDANgKIAhgcQAJgGAIgCIACAAIACAAIACAAIAFAEIAEAEIAHAPIAMAhQAIAWAGAMIAHAKQAJAJAbAVQAXARACAIIABADIAAAAQgBAGgJAJIgcAXIAAAAQgeAZgNArIgFAOQgDANgEAJQgFAKgFAFIgGAEIgFAAQgEAAgEgCg'
    );
    mask.setTransform(14.6, 16.025);

    // Capa_3
    this.instance = new lib.ClipGroup_0();
    this.instance.setTransform(14.7, 16.2, 1, 1, 0, 0, 0, 14.7, 16.3);

    var maskedShapeInstanceList = [this.instance];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup, new cjs.Rectangle(0, 0, 29.2, 32.1), null);

  (lib.ClipGroup_3_1 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_1 = new cjs.Shape();
    mask_1._off = true;
    mask_1.graphics.p(
      'AjzC9QgEgXAAgSQgCg5AegxIALgPQARgYArgtIAQgRQAYgbATgRQATgSAUgOQAPgLAOgHQAbgPAkgLIA3gRQAagGAOgCIAcgBQATABAOAGQAKAFAHAGQAGAEAGAJQAEAIAEAJIAFASIAHBAQAEBGgIBTQgEArgFAVIgBAHIAAABIAAABQgEAMgNAJIgFAEQgaAQgnAIIAAACIAAAAIgBAAQgVgCgdABIgRABQgyADgagDIgagEIg7gLQgigGgZgBQgYgBgbAGQgcAIgSANg'
    );
    mask_1.setTransform(24.9466, 20.625);

    // Capa_3
    this.instance = new lib.ClipGroup_0_1();
    this.instance.setTransform(25.1, 20.5, 1, 1, 0, 0, 0, 25.2, 20.9);

    var maskedShapeInstanceList = [this.instance];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_1;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_3_1, new cjs.Rectangle(0.2, 0, 49.599999999999994, 41.3), null);

  (lib.ClipGroup_2_4 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_4 = new cjs.Shape();
    mask_4._off = true;
    mask_4.graphics.p(
      'Ai0E9IgDgEQgKgRgGgRQgHgWgCgUQgEgaAAglQAAgeACgjIAMhXQAFgaACgHIABgCIACgDQADgFAFgFIAKgHIAOgHIASgGIAfgJIAVgGIgHgUQgMgpgPgqIgrh6QANgXAXgLIAPgFQARgFAWAAQAOAAAbAEIAwAKIABAAQAbAFAUACQAYACAYABIA2gBIAUgBQAIgBAIACQAFAXAGA7QAGA9glB6QgpCFg7BUQg8BWhaAtQgyAZgdAAQgWAAgLgOg'
    );
    mask_4.setTransform(21.3419, 33.1077);

    // Capa_3
    this.instance_3 = new lib.ClipGroup_1();
    this.instance_3.setTransform(21.65, 34.35, 1, 1, 0, 0, 0, 22.1, 34.6);

    var maskedShapeInstanceList = [this.instance_3];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_4;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_3).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_2_4, new cjs.Rectangle(0, 0, 42.7, 66.2), null);

  (lib.ClipGroup_1_0 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask = new cjs.Shape();
    mask._off = true;
    mask.graphics.p(
      'AnAElQgHgYAEgbIAGgVQAFgSAKgRQAHgNAQgYQAUgcAhglIAZgaIADgDIAJgIQAUgUAQgMIAGgFQAkgbAjgQQAygXA6gFIAqgCQA3ABA+gGIBPgJIhKjsIgBgDIgHgZQAGgEAOgHQAdgNAlgCIAKAAIABAAQAZABAoAHIBRAOIAZABIA7gCQAagBAaABIAIABQAJArgDA/QgEBxgpBxQguB8hIBFQgUAThwBNQgxAjhMASIgYAFQgYAEgYACIgTACIABAFQgMgBgYACIgoABQg3AAg2gLIghgHQgigHgWAAQgfAAgYALQgIAEgHAFQgOALgIANg'
    );
    mask.setTransform(45.4928, 36.825);

    // Capa_3
    this.instance = new lib.ClipGroup_4();
    this.instance.setTransform(46.4, 36.9, 1, 1, 0, 0, 0, 46.8, 37);

    var maskedShapeInstanceList = [this.instance];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_1_0, new cjs.Rectangle(0.2, 0, 90.7, 73.7), null);

  (lib.ClipGroup_7 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_1 = new cjs.Shape();
    mask_1._off = true;
    mask_1.graphics.p('An+NoIAA7PIP9AAIAAbPg');
    mask_1.setTransform(51.075, 87.15);

    // Capa_3
    this.instance_1 = new lib.ClipGroup_5();
    this.instance_1.setTransform(51.1, 87, 1, 1, 0, 0, 0, 51.1, 87);

    this.instance_2 = new lib.ClipGroup_1_0();
    this.instance_2.setTransform(48.2, 75.5, 1, 1, 0, 0, 0, 46.4, 36.9);

    this.instance_3 = new lib.ClipGroup_2_4();
    this.instance_3.setTransform(21.6, 142.15, 1, 1, 0, 0, 0, 21.6, 34.3);

    this.instance_4 = new lib.ClipGroup_3_1();
    this.instance_4.setTransform(77.5, 20.4, 1, 1, 0, 0, 0, 25.1, 20.4);

    var maskedShapeInstanceList = [this.instance_1, this.instance_2, this.instance_3, this.instance_4];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_1;
    }

    this.timeline.addTween(
      cjs.Tween.get({})
        .to({ state: [{ t: this.instance_4 }, { t: this.instance_3 }, { t: this.instance_2 }, { t: this.instance_1 }] })
        .wait(1)
    );

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_7, new cjs.Rectangle(0, 0, 102.2, 174.3), null);

  (lib.ClipGroup_17 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask = new cjs.Shape();
    mask._off = true;
    mask.graphics.p('AAEAPQgLgCgHgIIgEgFQgBgDABgDQABgIAIAAQAEAAAFADQAIACAFAGQAFADABADQACAEgDADQgDAFgGAAIgFAAg');
    mask.setTransform(1.97, 1.5);

    // Capa_3
    this.instance = new lib.ClipGroup_18();
    this.instance.setTransform(2, 1.5, 1, 1, 0, 0, 0, 2.1, 1.7);

    var maskedShapeInstanceList = [this.instance];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_17, new cjs.Rectangle(0, 0, 4, 3), null);

  (lib.ClipGroup_11 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask = new cjs.Shape();
    mask._off = true;
    mask.graphics.p('Al2CrIAAlVILtAAIAAFVg');
    mask.setTransform(47.475, 39.35);

    // Capa_3
    this.instance = new lib.ClipGroup_12();
    this.instance.setTransform(47.5, 38.8, 1, 1, 0, 0, 0, 47.5, 38.8);

    var maskedShapeInstanceList = [this.instance];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_11, new cjs.Rectangle(10, 22.3, 75, 34.2), null);

  (lib.ClipGroup_9 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask = new cjs.Shape();
    mask._off = true;
    mask.graphics.p('AiIB+IAAj7IERAAIAAD7g');
    mask.setTransform(21.75, 20.7);

    // Capa_3
    this.instance = new lib.ClipGroup_10();
    this.instance.setTransform(21.3, 20.8, 1, 1, 0, 0, 0, 21.3, 20.8);

    var maskedShapeInstanceList = [this.instance];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_9, new cjs.Rectangle(8.1, 8.1, 27.4, 25.199999999999996), null);

  (lib.ClipGroup_7_1 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask = new cjs.Shape();
    mask._off = true;
    mask.graphics.p('AkQDlIAAnJIIhAAIAAHJg');
    mask.setTransform(52.75, 54.175);

    // Capa_3
    this.instance = new lib.ClipGroup_8();
    this.instance.setTransform(53, 52.9, 1, 1, 0, 0, 0, 53, 52.9);

    var maskedShapeInstanceList = [this.instance];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_7_1, new cjs.Rectangle(25.5, 31.3, 54.5, 45.8), null);

  (lib.ClipGroup_5_0 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask = new cjs.Shape();
    mask._off = true;
    mask.graphics.p(
      'AgXBrQgSgBgUgIQgSgHgMgPQgJgKgEgNQgDgKgBgMIADgVQAFgPAKgRQAXgoAtgXQAqgWAxAAIATABQAHABANADIgGALQgDAGgDAIQgKAdADAdIABAOIACAHQACAEgCAEQgDAIgTAOIgMAMQgOAQgJANIgQAXQgGAGgGACQgHADgJAAIgHABIgHgBg'
    );
    mask.setTransform(10.85, 10.8);

    // Capa_3
    this.instance = new lib.ClipGroup_13();
    this.instance.setTransform(10.75, 11.1, 1, 1, 0, 0, 0, 11.2, 11.1);

    var maskedShapeInstanceList = [this.instance];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_5_0, new cjs.Rectangle(0, 0, 21.7, 21.6), null);

  (lib.ClipGroup_4_0 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask = new cjs.Shape();
    mask._off = true;
    mask.graphics.p(
      'Al7FWQhnh7Aai4QAbi5CRiSQBXhWBpgwQBmgtBkAAQCNAABcBWQCFB6gPDQQgQDPiWCRQi0Cui/AJIgUAAQirAAhwiGgAAzhOQgeAOgcAWQgaAVgZAyQgNAagHAVQAwgPAqgrQAWgVAYgqQAUgjAAgHQAAAAAAAAQgBgBAAAAQAAAAAAAAQgBAAAAAAQgFAAgUAKg'
    );
    mask.setTransform(46.1882, 47.6489);

    // Capa_3
    this.instance = new lib.Group_0();
    this.instance.setTransform(35.05, 90.7, 1, 1, 0, 0, 0, 11.5, 4);

    var maskedShapeInstanceList = [this.instance];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_4_0, new cjs.Rectangle(23.6, 86.7, 23, 8), null);

  (lib.ClipGroup_4_3 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_3 = new cjs.Shape();
    mask_3._off = true;
    mask_3.graphics.p('AjKBIIAAiPIGVAAIAACPg');
    mask_3.setTransform(23.95, 17.375);

    // Capa_3
    this.instance_2 = new lib.ClipGroup_5_1();
    this.instance_2.setTransform(24.4, 17.3, 1, 1, 0, 0, 0, 24.4, 17.3);

    var maskedShapeInstanceList = [this.instance_2];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_3;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_2).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_4_3, new cjs.Rectangle(3.6, 10.2, 40.699999999999996, 14.400000000000002), null);

  (lib.ClipGroup_3_0 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask = new cjs.Shape();
    mask._off = true;
    mask.graphics.p('AgiAUQgMgDgFgKQgFgIAKgGQAIgEAVAAQAOAAAFgCIAAAAIAQgDQAPgEAIAAQANAAgBAHQgBAFgEADIgJAGQgUAOgVAFIgQABQgIAAgIgBg');
    mask.setTransform(5.4564, 2.1);

    // Capa_3
    this.instance = new lib.ClipGroup_14();
    this.instance.setTransform(5.35, 2.1, 1, 1, 0, 0, 0, 5.5, 2.1);

    var maskedShapeInstanceList = [this.instance];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_3_0, new cjs.Rectangle(0.1, 0, 10.700000000000001, 4.2), null);

  (lib.ClipGroup_2_0 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask = new cjs.Shape();
    mask._off = true;
    mask.graphics.p('AAKAVIgRgIIgRgEQgKgDgEgGQgEgEAGgHQAEgEAIgDQAJgEAKAAIALACQAVADAKAOQAEAFgBAGQgCAHgHAEQgGAEgHAAIgIgCg');
    mask.setTransform(4.4507, 2.2529);

    // Capa_3
    this.instance = new lib.ClipGroup_15();
    this.instance.setTransform(4.15, 2.25, 1, 1, 0, 0, 0, 4.5, 2.4);

    var maskedShapeInstanceList = [this.instance];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_2_0, new cjs.Rectangle(0.4, 0, 8.1, 4.5), null);

  (lib.ClipGroup_2_5 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_5 = new cjs.Shape();
    mask_5._off = true;
    mask_5.graphics.p('AiSBNIAAiZIElAAIAACZg');
    mask_5.setTransform(20.55, 16.425);

    // Capa_3
    this.instance_4 = new lib.ClipGroup_3();
    this.instance_4.setTransform(19.9, 16.6, 1, 1, 0, 0, 0, 19.9, 16.6);

    var maskedShapeInstanceList = [this.instance_4];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_5;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_4).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_2_5, new cjs.Rectangle(5.9, 8.8, 29.4, 15.3), null);

  (lib.ClipGroup_1_0_1 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_1 = new cjs.Shape();
    mask_1._off = true;
    mask_1.graphics.p('AguAJQACgGAJgHIAMgGQAOgHAKgDIAPgBQALAAAKAEQAGADAEAHQAEAJgNACQgNACgRAFIgcAJQgJACgGAAQgOAAADgNg');
    mask_1.setTransform(5.0482, 2.2435);

    // Capa_3
    this.instance_1 = new lib.ClipGroup_16();
    this.instance_1.setTransform(4.75, 2.35, 1, 1, 0, 0, 0, 5.2, 2.6);

    var maskedShapeInstanceList = [this.instance_1];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_1;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_1_0_1, new cjs.Rectangle(0.3, 0, 9.5, 4.5), null);

  (lib.ClipGroup_0_26 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_26 = new cjs.Shape();
    mask_26._off = true;
    mask_26.graphics.p('AlQH7IAAv1IKhAAIAAP1g');
    mask_26.setTransform(62.55, 70.65);

    // Capa_3
    this.instance_26 = new lib.ClipGroup_1_1();
    this.instance_26.setTransform(63.6, 70.5, 1, 1, 0, 0, 0, 63.6, 70.5);

    var maskedShapeInstanceList = [this.instance_26];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_26;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_26).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_0_26, new cjs.Rectangle(28.9, 20, 67.30000000000001, 101.4), null);

  (lib.ClipGroup_7_2 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_1 = new cjs.Shape();
    mask_1._off = true;
    mask_1.graphics.p('AtpMJIAA4RIbTAAIAAYRg');
    mask_1.setTransform(161.8, 160.7);

    // Capa_3
    this.instance_1 = new lib.ClipGroup_8_1();
    this.instance_1.setTransform(161.3, 160.8, 1, 1, 0, 0, 0, 161.3, 160.8);

    var maskedShapeInstanceList = [this.instance_1];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_1;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_7_2, new cjs.Rectangle(74.4, 83, 174.79999999999998, 155.5), null);

  (lib.ClipGroup_5_3 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_3 = new cjs.Shape();
    mask_3._off = true;
    mask_3.graphics.p('Al0CGIAAkLILpAAIAAELg');
    mask_3.setTransform(42.15, 28.85);

    // Capa_3
    this.instance_2 = new lib.ClipGroup_6();
    this.instance_2.setTransform(42.1, 27.2, 1, 1, 0, 0, 0, 42.1, 27.2);

    var maskedShapeInstanceList = [this.instance_2];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_3;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_2).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_5_3, new cjs.Rectangle(4.9, 15.5, 74.6, 26.799999999999997), null);

  (lib.ClipGroup_3_2 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_2 = new cjs.Shape();
    mask_2._off = true;
    mask_2.graphics.p('AlBDLIAAmVIKDAAIAAGVg');
    mask_2.setTransform(52.2, 50.675);

    // Capa_3
    this.instance_1 = new lib.ClipGroup_4_1();
    this.instance_1.setTransform(52.5, 51.8, 1, 1, 0, 0, 0, 52.5, 51.8);

    var maskedShapeInstanceList = [this.instance_1];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_2;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_3_2, new cjs.Rectangle(20, 30.4, 64.5, 40.6), null);

  (lib.ClipGroup_1_4 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_4 = new cjs.Shape();
    mask_4._off = true;
    mask_4.graphics.p('Al6ReMAAAgi7IL1AAMAAAAi7g');
    mask_4.setTransform(100.825, 133.775);

    // Capa_3
    this.instance_3 = new lib.ClipGroup_2();
    this.instance_3.setTransform(101.9, 133.8, 1, 1, 0, 0, 0, 101.9, 133.8);

    var maskedShapeInstanceList = [this.instance_3];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_4;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_3).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_1_4, new cjs.Rectangle(62.9, 22, 75.9, 223.6), null);

  (lib.ClipGroup_3_3 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_3 = new cjs.Shape();
    mask_3._off = true;
    mask_3.graphics.p(
      'AjfC0QgUg8gTg9IgXhPQACg5AQgpQAUgzAogVQAXgNAzgKQAqgIA0gEIA1gDIA0gBICyAJQALAgAGAaQAJAhAHApIAEAeQAEAkABApQAAAqgFAqQgFAqgHAkIgGAYIgmgLQghgJgwgLIg5gKQgPgDgegDIgKgBIgdAOQgSAJgnAPIgZAJQgfAKgeAHQgQAEgWADQgRACgLAAIgRgyg'
    );
    mask_3.setTransform(28.575, 23.025);

    // Capa_3
    this.instance_2 = new lib.ClipGroup_0_2();
    this.instance_2.setTransform(28.65, 23.05, 1, 1, 0, 0, 0, 28.8, 23.2);

    var maskedShapeInstanceList = [this.instance_2];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_3;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_2).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_3_3, new cjs.Rectangle(0, 0, 57.2, 46.1), null);

  (lib.ClipGroup_2_6 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_6 = new cjs.Shape();
    mask_6._off = true;
    mask_6.graphics.p(
      'AhjG9QgLgFgLgLQgJgKgHgPIgNgfIgQgvIgLgsIgBgBIgBgLQgJgqAEg0IADgZIAJg5IAGgnQAAgFgEgSIgHgXIhekrIAEgwQAMhNAdgOQAjgRB/AAIBaACIAjACIC9gCIALBDQAFAeADAqIACAfQAEBlgWBpIgPA8QgcBfhHBzQgsBGgzA/QguA5glAgIgGAFQgPANgPAEIgKABQgHAAgHgCg'
    );
    mask_6.setTransform(27.2052, 44.7143);

    // Capa_3
    this.instance_5 = new lib.ClipGroup_1_2();
    this.instance_5.setTransform(27.4, 44.8, 1, 1, 0, 0, 0, 27.4, 45.1);

    var maskedShapeInstanceList = [this.instance_5];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_6;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_5).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_2_6, new cjs.Rectangle(0, 0, 54.4, 89.5), null);

  (lib.ClipGroup_1_0_2 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_2 = new cjs.Shape();
    mask_2._off = true;
    mask_2.graphics.p(
      'AnAGaQgPg7gJgqIgHghQgDgXABgUQAAgkALgnQAThGAyhJQAZgkArg0IBDhKIA8g+QAbgbAYgTQAWgUAWgPIAggXIAcgRQAngXAsgQIAggLIAkgKQAfgHAlgHIBegOIBEgMQAQgDAQgBIAOABQAOACALAHQAXAQAQAuQAHAVAGAXQAHAgAIA1IALBQQACAdABAhQABAsgDAgQgBAVgEAXIAAABQgFAbgGAVIAAABIAAAAQgIAZgHARIgGAMQgRAggUAPQgPALgpALIggAGQhIAOhiAJIg8AEQgtgGhTgDIgPAAQgkgBgvABIgqADQhJAEgyALQgUAFgUAHIgUAJQgjASgVAtQgTArgDBAg'
    );
    mask_2.setTransform(48.2839, 43.45);

    // Capa_3
    this.instance_2 = new lib.ClipGroup_4_2();
    this.instance_2.setTransform(48.1, 43.2, 1, 1, 0, 0, 0, 48.5, 43.6);

    var maskedShapeInstanceList = [this.instance_2];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_2;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_2).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_1_0_2, new cjs.Rectangle(0.1, 0, 96.4, 86.9), null);

  (lib.ClipGroup_19 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_2 = new cjs.Shape();
    mask_2._off = true;
    mask_2.graphics.p('AtTUoMAAAgpQIanAAMAAAApQg');
    mask_2.setTransform(85.325, 132.05);

    // Capa_3
    this.instance_5 = new lib.ClipGroup_5_2();
    this.instance_5.setTransform(85.2, 132.1, 1, 1, 0, 0, 0, 85.2, 132.1);

    this.instance_6 = new lib.ClipGroup_1_0_2();
    this.instance_6.setTransform(122.05, 43.25, 1, 1, 0, 0, 0, 48.1, 43.2);

    this.instance_7 = new lib.ClipGroup_2_6();
    this.instance_7.setTransform(32.35, 219.45, 1, 1, 0, 0, 0, 27.4, 44.8);

    this.instance_8 = new lib.ClipGroup_3_3();
    this.instance_8.setTransform(106.75, 89.15, 1, 1, 0, 0, 0, 28.7, 23.1);

    var maskedShapeInstanceList = [this.instance_5, this.instance_6, this.instance_7, this.instance_8];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_2;
    }

    this.timeline.addTween(
      cjs.Tween.get({})
        .to({ state: [{ t: this.instance_8 }, { t: this.instance_7 }, { t: this.instance_6 }, { t: this.instance_5 }] })
        .wait(1)
    );

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_19, new cjs.Rectangle(0.2, 0, 170.3, 264.1), null);

  (lib.ClipGroup_20 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_3 = new cjs.Shape();
    mask_3._off = true;
    mask_3.graphics.p(
      'AFvWoQgVAAgegJQgcgKgggSQgxgahAgtIhfhFIhjhJQiYhuhngvQhIggg8gKQhHgLhtgDQg+gBiLABQiWAChFgCQhygEhFgQQhZgVgeguQgSgegEgvQgEg8AZhhQANg2AriFQBXkKAAh9QAAh3gZiPQgbiagxiPQgehZgcg8QgYgygKgqQgHggAAgiQAAgVAFgVQAEgVANgXIAEgHQAXgiA6gSQAegJAtgFQBAgIBpgBIBWABQCDAAA9gDQBlgFA8gRQD4hJGbk2IAHgGQBCgxAsgTQApgSAiAAQAXAAAXAJQATAHARANQAnAcAoBBQAUAhAaA6QAPAiANAeIAgBXIA7CgQA4CMA/BgQAhA0AkArQBHBVBfBPQBhBQCKBWIAaARQBEApAzArQA6AyAYAwQAOAeAAAfIAAAAQAAAjgVAiQgbAthBAyQgMAJgfAWQgkAXhAAvQhEAwg8AyQkEDXhfDWQgeBFgfBuIguC6QgbBrgVA2QgXA4gaAfQgeAjgmAIIgCABIgRABg'
    );
    mask_3.setTransform(139.9, 144.825);

    // Capa_3
    this.instance_9 = new lib.ClipGroup_0_3();
    this.instance_9.setTransform(139.95, 144.75, 1, 1, 0, 0, 0, 140.4, 145);

    var maskedShapeInstanceList = [this.instance_9];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_3;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_9).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_20, new cjs.Rectangle(0, 0, 279.8, 289.7), null);

  (lib.ClipGroup_21 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_4 = new cjs.Shape();
    mask_4._off = true;
    mask_4.graphics.p('AqvKwIAA1fIVfAAIAAVfg');
    mask_4.setTransform(68.775, 68.775);

    // Capa_3
    this.instance_10 = new lib.ClipGroup_0_4();
    this.instance_10.setTransform(69, 69, 1, 1, 0, 0, 0, 69, 69);

    var maskedShapeInstanceList = [this.instance_10];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_4;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_10).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_21, new cjs.Rectangle(0, 0, 137.6, 137.6), null);

  (lib.ClipGroup_22 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_5 = new cjs.Shape();
    mask_5._off = true;
    mask_5.graphics.p('AqvKwIAA1fIVfAAIAAVfg');
    mask_5.setTransform(68.775, 68.775);

    // Capa_3
    this.instance_11 = new lib.ClipGroup_0_5();
    this.instance_11.setTransform(69, 69, 1, 1, 0, 0, 0, 69, 69);

    var maskedShapeInstanceList = [this.instance_11];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_5;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_11).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_22, new cjs.Rectangle(0, 0, 137.6, 137.6), null);

  (lib.ClipGroup_23 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_6 = new cjs.Shape();
    mask_6._off = true;
    mask_6.graphics.p('AqvKwIAA1fIVfAAIAAVfg');
    mask_6.setTransform(68.775, 68.775);

    // Capa_3
    this.instance_12 = new lib.ClipGroup_0_6();
    this.instance_12.setTransform(69, 69, 1, 1, 0, 0, 0, 69, 69);

    var maskedShapeInstanceList = [this.instance_12];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_6;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_12).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_23, new cjs.Rectangle(0, 0, 137.6, 137.6), null);

  (lib.ClipGroup_24 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_7 = new cjs.Shape();
    mask_7._off = true;
    mask_7.graphics.p('AqvKwIAA1fIVfAAIAAVfg');
    mask_7.setTransform(68.775, 68.775);

    // Capa_3
    this.instance_13 = new lib.ClipGroup_0_7();
    this.instance_13.setTransform(69, 69, 1, 1, 0, 0, 0, 69, 69);

    var maskedShapeInstanceList = [this.instance_13];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_7;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_13).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_24, new cjs.Rectangle(0, 0, 137.6, 137.6), null);

  (lib.ClipGroup_25 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_8 = new cjs.Shape();
    mask_8._off = true;
    mask_8.graphics.p('AqvKwIAA1fIVfAAIAAVfg');
    mask_8.setTransform(68.775, 68.775);

    // Capa_3
    this.instance_14 = new lib.ClipGroup_0_8();
    this.instance_14.setTransform(69, 69, 1, 1, 0, 0, 0, 69, 69);

    var maskedShapeInstanceList = [this.instance_14];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_8;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_14).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_25, new cjs.Rectangle(0, 0, 137.6, 137.6), null);

  (lib.ClipGroup_26 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_9 = new cjs.Shape();
    mask_9._off = true;
    mask_9.graphics.p('AqvKwIAA1fIVfAAIAAVfg');
    mask_9.setTransform(68.775, 68.775);

    // Capa_3
    this.instance_15 = new lib.ClipGroup_0_9();
    this.instance_15.setTransform(69, 69, 1, 1, 0, 0, 0, 69, 69);

    var maskedShapeInstanceList = [this.instance_15];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_9;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_15).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_26, new cjs.Rectangle(0, 0, 137.6, 137.6), null);

  (lib.ClipGroup_27 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_10 = new cjs.Shape();
    mask_10._off = true;
    mask_10.graphics.p('AqvKwIAA1fIVfAAIAAVfg');
    mask_10.setTransform(68.775, 68.775);

    // Capa_3
    this.instance_16 = new lib.ClipGroup_0_10();
    this.instance_16.setTransform(69, 69, 1, 1, 0, 0, 0, 69, 69);

    var maskedShapeInstanceList = [this.instance_16];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_10;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_16).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_27, new cjs.Rectangle(0, 0, 137.6, 137.6), null);

  (lib.ClipGroup_28 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_11 = new cjs.Shape();
    mask_11._off = true;
    mask_11.graphics.p('AqvKwIAA1fIVfAAIAAVfg');
    mask_11.setTransform(68.775, 68.775);

    // Capa_3
    this.instance_17 = new lib.ClipGroup_0_11();
    this.instance_17.setTransform(69, 69, 1, 1, 0, 0, 0, 69, 69);

    var maskedShapeInstanceList = [this.instance_17];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_11;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_17).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_28, new cjs.Rectangle(0, 0, 137.6, 137.6), null);

  (lib.ClipGroup_29 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_12 = new cjs.Shape();
    mask_12._off = true;
    mask_12.graphics.p('At7QXMAAAggtIb3AAMAAAAgtg');
    mask_12.setTransform(89.675, 104.725);

    // Capa_3
    this.instance_18 = new lib.ClipGroup_0_12();
    this.instance_18.setTransform(89.5, 105, 1, 1, 0, 0, 0, 89.5, 105);

    var maskedShapeInstanceList = [this.instance_18];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_12;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_18).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_29, new cjs.Rectangle(0.5, 0, 178.4, 209.5), null);

  (lib.ClipGroup_3_4 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_4 = new cjs.Shape();
    mask_4._off = true;
    mask_4.graphics.p('AtmNOIAA6cIbMAAIAAacg');
    mask_4.setTransform(87.05, 84.65);

    // Capa_3
    this.instance_3 = new lib.Group_0_1();
    this.instance_3.setTransform(59.85, 153.3, 1, 1, 0, 0, 0, 31.5, 13.5);

    var maskedShapeInstanceList = [this.instance_3];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_4;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_3).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_3_4, new cjs.Rectangle(28.4, 139.8, 63.00000000000001, 27), null);

  (lib.ClipGroup_1_5 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_5 = new cjs.Shape();
    mask_5._off = true;
    mask_5.graphics.p('AhgAfIgQgrIgDgGICphKIAwgRIAGgDIAAAJIAEAbIAEAcIAAB6IgFABIgpAKIiAAcg');
    mask_5.setTransform(11.575, 11.25);

    // Capa_3
    this.instance_4 = new lib.ClipGroup_0_13();
    this.instance_4.setTransform(11.55, 11.1, 1, 1, 0, 0, 0, 12, 11.5);

    var maskedShapeInstanceList = [this.instance_4];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_5;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_4).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_1_5, new cjs.Rectangle(0, 0, 23.2, 22.5), null);

  (lib.ClipGroup_30 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_13 = new cjs.Shape();
    mask_13._off = true;
    mask_13.graphics.p('AtmNOIAA6cIbNAAIAAacg');
    mask_13.setTransform(87.35, 84.85);

    // Capa_3
    this.instance_19 = new lib.ClipGroup_3_4();
    this.instance_19.setTransform(87.3, 84.9, 1, 1, 0, 0, 0, 87, 84.7);

    this.instance_20 = new lib.ClipGroup_1_5();
    this.instance_20.setTransform(81.95, 62.95, 1, 1, 0, 0, 0, 11.6, 11.1);

    this.instance_21 = new lib.ClipGroup_2_1();
    this.instance_21.setTransform(87.5, 85.5, 1, 1, 0, 0, 0, 87.5, 85.5);

    var maskedShapeInstanceList = [this.instance_19, this.instance_20, this.instance_21];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_13;
    }

    this.timeline.addTween(
      cjs.Tween.get({})
        .to({ state: [{ t: this.instance_21 }, { t: this.instance_20 }, { t: this.instance_19 }] })
        .wait(1)
    );

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_30, new cjs.Rectangle(0.3, 0.2, 174.1, 169.3), null);

  (lib.ClipGroup_31 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_14 = new cjs.Shape();
    mask_14._off = true;
    mask_14.graphics.p('AseOvIAA9dIY8AAIAAddg');
    mask_14.setTransform(79.85, 94.35);

    // Capa_3
    this.instance_22 = new lib.ClipGroup_0_14();
    this.instance_22.setTransform(80, 94.5, 1, 1, 0, 0, 0, 80, 94.5);

    var maskedShapeInstanceList = [this.instance_22];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_14;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_22).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_31, new cjs.Rectangle(0, 0.1, 159.7, 188.6), null);

  (lib.ClipGroup_32 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_15 = new cjs.Shape();
    mask_15._off = true;
    mask_15.graphics.p('AtYM5IAA5xIaxAAIAAZxg');
    mask_15.setTransform(85.725, 82.55);

    // Capa_3
    this.instance_23 = new lib.ClipGroup_0_15();
    this.instance_23.setTransform(86, 82.5, 1, 1, 0, 0, 0, 86, 82.5);

    var maskedShapeInstanceList = [this.instance_23];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_15;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_23).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_32, new cjs.Rectangle(0.1, 0.1, 171.3, 165), null);

  (lib.ClipGroup_0_27 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_27 = new cjs.Shape();
    mask_27._off = true;
    mask_27.graphics.p('AiRBFQgjhAgHgKIgGgJIDpiWIAhgSQAbgNAHgFIAIgFIACAOQACAJAOAmQAOAnACAJIAtDVIj9BZg');
    mask_27.setTransform(19.375, 20.6);

    // Capa_3
    this.instance_27 = new lib.ClipGroup_2_2();
    this.instance_27.setTransform(19.4, 20.7, 1, 1, 0, 0, 0, 19.4, 20.9);

    var maskedShapeInstanceList = [this.instance_27];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_27;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_27).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_0_27, new cjs.Rectangle(0, 0, 38.8, 41.2), null);

  (lib.ClipGroup_33 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_16 = new cjs.Shape();
    mask_16._off = true;
    mask_16.graphics.p('AuMODIAA8FIcZAAIAAcFg');
    mask_16.setTransform(91.15, 89.95);

    // Capa_3
    this.instance_24 = new lib.ClipGroup_0_27();
    this.instance_24.setTransform(37.85, 56.25, 1, 1, 0, 0, 0, 19.4, 20.7);

    this.instance_25 = new lib.ClipGroup_1_3();
    this.instance_25.setTransform(91.5, 90, 1, 1, 0, 0, 0, 91.5, 90);

    var maskedShapeInstanceList = [this.instance_24, this.instance_25];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_16;
    }

    this.timeline.addTween(
      cjs.Tween.get({})
        .to({ state: [{ t: this.instance_25 }, { t: this.instance_24 }] })
        .wait(1)
    );

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_33, new cjs.Rectangle(0.3, 0.1, 181.79999999999998, 179.70000000000002), null);

  (lib.ClipGroup_34 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_17 = new cjs.Shape();
    mask_17._off = true;
    mask_17.graphics.p('A2dYQMAAAgwfMAs7AAAMAAAAwfg');
    mask_17.setTransform(143.85, 155.325);

    // Capa_3
    this.instance_26 = new lib.ClipGroup_0_16();
    this.instance_26.setTransform(144, 155.5, 1, 1, 0, 0, 0, 144, 155.5);

    var maskedShapeInstanceList = [this.instance_26];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_17;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_26).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_34, new cjs.Rectangle(0.1, 0.1, 287.59999999999997, 310.5), null);

  (lib.ClipGroup_35 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_18 = new cjs.Shape();
    mask_18._off = true;
    mask_18.graphics.p('Egb6AoFMAAAhQJMA31AAAMAAABQJg');
    mask_18.setTransform(178.7, 256.575);

    // Capa_3
    this.instance_27 = new lib.ClipGroup_0_17();
    this.instance_27.setTransform(179, 257, 1, 1, 0, 0, 0, 179, 257);

    var maskedShapeInstanceList = [this.instance_27];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_18;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_27).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_35, new cjs.Rectangle(0, 0.1, 357.4, 513), null);

  (lib.ClipGroup_36 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_19 = new cjs.Shape();
    mask_19._off = true;
    mask_19.graphics.p('ArkPvIAA/dIXJAAIAAfdg');
    mask_19.setTransform(74.2, 100.725);

    // Capa_3
    this.instance_28 = new lib.ClipGroup_0_18();
    this.instance_28.setTransform(74.5, 101, 1, 1, 0, 0, 0, 74.5, 101);

    var maskedShapeInstanceList = [this.instance_28];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_19;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_28).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_36, new cjs.Rectangle(0.1, 0.1, 148.3, 201.3), null);

  (lib.ClipGroup_5_4 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_4 = new cjs.Shape();
    mask_4._off = true;
    mask_4.graphics.p('AirAqIgwghIgUgRIgDgBIHlggIgCADQgBACABAJQABAIgCACIghAvIjvAFIiAAHg');
    mask_4.setTransform(24.275, 4.2);

    // Capa_3
    this.instance_3 = new lib.ClipGroup_6_1();
    this.instance_3.setTransform(24.25, 4.1, 1, 1, 0, 0, 0, 24.4, 4.5);

    var maskedShapeInstanceList = [this.instance_3];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_4;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_3).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_5_4, new cjs.Rectangle(0, 0, 48.6, 8.4), null);

  (lib.ClipGroup_4_4 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_4 = new cjs.Shape();
    mask_4._off = true;
    mask_4.graphics.p('AjyAqIAAhTIHlAAIAABTg');
    mask_4.setTransform(24.275, 4.2);

    // Capa_3
    this.instance_3 = new lib.ClipGroup_5_4();
    this.instance_3.setTransform(24.3, 4.2, 1, 1, 0, 0, 0, 24.3, 4.2);

    var maskedShapeInstanceList = [this.instance_3];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_4;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_3).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_4_4, new cjs.Rectangle(0, 0, 48.6, 8.4), null);

  (lib.ClipGroup_1_6 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_6 = new cjs.Shape();
    mask_6._off = true;
    mask_6.graphics.p('Ag/A+IhhAGIgIgBIgNhBIgDgiIgBgFIFNgaIAdgDIAJgBIgEAGIgHAVQgFAQgCAEIg4BYg');
    mask_6.setTransform(18.575, 6.75);

    // Capa_3
    this.instance_5 = new lib.ClipGroup_0_19();
    this.instance_5.setTransform(18.65, 6.7, 1, 1, 0, 0, 0, 18.7, 7);

    var maskedShapeInstanceList = [this.instance_5];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_6;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_5).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_1_6, new cjs.Rectangle(0, 0, 37.2, 13.5), null);

  (lib.ClipGroup_37 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_20 = new cjs.Shape();
    mask_20._off = true;
    mask_20.graphics.p('AsHLWIAA2rIYPAAIAAWrg');
    mask_20.setTransform(77.675, 72.65);

    // Capa_3
    this.instance_29 = new lib.ClipGroup_0_20();
    this.instance_29.setTransform(78, 73, 1, 1, 0, 0, 0, 78, 73);

    var maskedShapeInstanceList = [this.instance_29];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_20;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_29).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_37, new cjs.Rectangle(0.1, 0.1, 155.20000000000002, 145.20000000000002), null);

  (lib.ClipGroup_38 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_21 = new cjs.Shape();
    mask_21._off = true;
    mask_21.graphics.p('AxCRwMAAAgjfMAiFAAAMAAAAjfg');
    mask_21.setTransform(109.2, 113.625);

    // Capa_3
    this.instance_30 = new lib.ClipGroup_0_21();
    this.instance_30.setTransform(109.5, 114, 1, 1, 0, 0, 0, 109.5, 114);

    var maskedShapeInstanceList = [this.instance_30];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_21;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_30).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_38, new cjs.Rectangle(0.1, 0.1, 218.3, 227.1), null);

  (lib.ClipGroup_39 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_22 = new cjs.Shape();
    mask_22._off = true;
    mask_22.graphics.p('AnMFYIAAqvIOZAAIAAKvg');
    mask_22.setTransform(46.125, 34.475);

    // Capa_3
    this.instance_31 = new lib.ClipGroup_0_22();
    this.instance_31.setTransform(46.5, 34.5, 1, 1, 0, 0, 0, 46.5, 34.5);

    var maskedShapeInstanceList = [this.instance_31];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_22;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_31).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_39, new cjs.Rectangle(0, 0.1, 92.3, 68.80000000000001), null);

  (lib.ClipGroup_40 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_23 = new cjs.Shape();
    mask_23._off = true;
    mask_23.graphics.p('Ao9I1IAAxpIR7AAIAARpg');
    mask_23.setTransform(57.4, 56.575);

    // Capa_3
    this.instance_32 = new lib.ClipGroup_0_23();
    this.instance_32.setTransform(57.5, 57, 1, 1, 0, 0, 0, 57.5, 57);

    var maskedShapeInstanceList = [this.instance_32];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_23;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_32).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_40, new cjs.Rectangle(0, 0.1, 114.8, 113), null);

  (lib.ClipGroup_41 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_24 = new cjs.Shape();
    mask_24._off = true;
    mask_24.graphics.p('AoDJ4IAAzvIQHAAIAATvg');
    mask_24.setTransform(51.725, 63.525);

    // Capa_3
    this.instance_33 = new lib.ClipGroup_0_24();
    this.instance_33.setTransform(52, 63.5, 1, 1, 0, 0, 0, 52, 63.5);

    var maskedShapeInstanceList = [this.instance_33];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_24;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_33).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_41, new cjs.Rectangle(0.2, 0.4, 103.1, 126.3), null);

  (lib.ClipGroup_42 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_25 = new cjs.Shape();
    mask_25._off = true;
    mask_25.graphics.p('AmkIEIAAwHINJAAIAAQHg');
    mask_25.setTransform(42.125, 51.7);

    // Capa_3
    this.instance_34 = new lib.ClipGroup_0_25();
    this.instance_34.setTransform(42.5, 52, 1, 1, 0, 0, 0, 42.5, 52);

    var maskedShapeInstanceList = [this.instance_34];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_25;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_34).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_42, new cjs.Rectangle(0.1, 0.1, 84.10000000000001, 103.30000000000001), null);

  (lib.Interpolación33 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // FlashAICB
    this.instance = new lib.ClipGroup_21();
    this.instance.setTransform(0.5, 0.3, 0.1656, 0.1656, 0, 0, 0, 68.9, 68.9);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-10.9, -11.1, 22.9, 22.9);

  (lib.Interpolación32 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // FlashAICB
    this.instance = new lib.ClipGroup_22();
    this.instance.setTransform(0.5, 0.3, 0.2691, 0.2691, 0, 0, 0, 68.9, 68.9);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-18, -18.2, 37.1, 37.099999999999994);

  (lib.Interpolación31 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // FlashAICB
    this.instance = new lib.ClipGroup_23();
    this.instance.setTransform(0.5, 0.3, 0.2691, 0.2691, 0, 0, 0, 68.9, 68.9);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-18, -18.2, 37.1, 37.099999999999994);

  (lib.Interpolación30 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // FlashAICB
    this.instance = new lib.ClipGroup_24();
    this.instance.setTransform(0.5, 0.3, 0.313, 0.313, 0, 0, 0, 69, 69);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-21.1, -21.3, 43.2, 43.2);

  (lib.Interpolación29 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // FlashAICB
    this.instance = new lib.ClipGroup_25();
    this.instance.setTransform(0.5, 0.3, 0.2691, 0.2691, 0, 0, 0, 68.9, 68.9);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-18, -18.2, 37.1, 37.099999999999994);

  (lib.Interpolación28 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // FlashAICB
    this.instance = new lib.ClipGroup_26();
    this.instance.setTransform(0.5, 0.3, 0.313, 0.313, 0, 0, 0, 69, 69);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-21.1, -21.3, 43.2, 43.2);

  (lib.Interpolación27 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_1
    this.instance = new lib.ClipGroup_27();
    this.instance.setTransform(0.3, 0.1, 0.1916, 0.1916, 0, 0, 0, 68.6, 68.6);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-12.8, -13, 26.4, 26.4);

  (lib.Interpolación26 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_1
    this.instance = new lib.ClipGroup_27();
    this.instance.setTransform(0.3, 0.1, 0.2024, 0.2024, 0, 0, 0, 68.7, 68.7);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-13.6, -13.8, 28, 28);

  (lib.Interpolación25 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_1
    this.instance = new lib.ClipGroup_27();
    this.instance.setTransform(0.4, 0.15, 0.2425, 0.2425, 0, 0, 0, 68.9, 68.9);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-16.3, -16.5, 33.5, 33.4);

  (lib.Interpolación24 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_1
    this.instance = new lib.ClipGroup_27();
    this.instance.setTransform(0.4, 0.2, 0.4086, 0.4086, 0, 0, 0, 68.9, 69);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-27.7, -28, 56.4, 56.4);

  (lib.Interpolación23 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_1
    this.instance = new lib.ClipGroup_27();
    this.instance.setTransform(0.4, 0.15, 0.2425, 0.2425, 0, 0, 0, 68.9, 68.9);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-16.3, -16.5, 33.5, 33.4);

  (lib.Interpolación22 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_1
    this.instance = new lib.ClipGroup_27();
    this.instance.setTransform(-1.6, -1.6, 0.5412, 0.5412, 0, 0, 0, 69, 69);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-38.9, -38.9, 74.69999999999999, 74.69999999999999);

  (lib.Interpolación21 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_1
    this.instance = new lib.ClipGroup_27();
    this.instance.setTransform(0.7, 0.2, 0.2349, 0.2349, 0, 0, 0, 69, 69);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-15.5, -16, 32.4, 32.4);

  (lib.Interpolación20 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // FlashAICB
    this.instance = new lib.ClipGroup_27();
    this.instance.setTransform(0.8, 0.3, 0.5412, 0.5412, 0, 0, 0, 69, 69);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-36.5, -37, 74.7, 74.7);

  (lib.Interpolación19 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_1
    this.instance = new lib.ClipGroup_28();
    this.instance.setTransform(0.65, 0.15, 0.3259, 0.3259, 0, 0, 0, 68.9, 68.9);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-21.8, -22.3, 45, 45);

  (lib.Interpolación18 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // FlashAICB
    this.instance = new lib.ClipGroup_28();
    this.instance.setTransform(0.8, 0.3, 0.6448, 0.6448, 0, 0, 0, 69, 69);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-43.7, -44.2, 89, 89);

  (lib.Interpolación17 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // FlashAICB
    this.instance = new lib.ClipGroup_29();
    this.instance.setTransform(0.35, 0.3, 0.5251, 0.5251, 0, 0, 0, 89.5, 104.9);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-46.6, -54.8, 94, 110.3);

  (lib.Interpolación16 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // FlashAICB
    this.instance = new lib.ClipGroup_30();
    this.instance.setTransform(-0.3, 0.7, 0.5742, 0.5742, 0, 0, 0, 87.5, 85.5);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-50.5, -48.4, 100.5, 98.19999999999999);

  (lib.Interpolación15 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // FlashAICB
    this.instance = new lib.ClipGroup_31();
    this.instance.setTransform(0.65, 0.15, 0.3482, 0.3482, 0, 0, 0, 80, 94.5);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-27.2, -32.7, 55.7, 65.80000000000001);

  (lib.Interpolación14 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // FlashAICB
    this.instance = new lib.ClipGroup_32();
    this.instance.setTransform(0.85, 0, 0.4875, 0.4875, 0, 0, 0, 86, 82.5);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-41.1, -40.2, 83.9, 80.5);

  (lib.Interpolación13 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // FlashAICB
    this.instance = new lib.ClipGroup_33();
    this.instance.setTransform(-0.15, 0.05, 0.6024, 0.6024, 0, 0, 0, 91.5, 90);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-55.2, -54.1, 110.2, 108.4);

  (lib.Interpolación12 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // FlashAICB
    this.instance = new lib.ClipGroup_34();
    this.instance.setTransform(0.65, 0.2, 0.6075, 0.6075, 0, 0, 0, 144, 155.5);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-86.8, -94.2, 175, 188.9);

  (lib.Interpolación11 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // FlashAICB
    this.instance = new lib.ClipGroup_35();
    this.instance.setTransform(0.8, 0.4, 0.416, 0.416, 0, 0, 0, 179, 256.9);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-73.6, -106.4, 148.89999999999998, 213.8);

  (lib.Interpolación10 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // FlashAICB
    this.instance = new lib.ClipGroup_36();
    this.instance.setTransform(0.8, 0.35, 0.9499, 0.9499, 0, 0, 0, 74.5, 101);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-69.9, -95.6, 141.5, 191.89999999999998);

  (lib.Interpolación8 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // FlashAICB
    this.instance = new lib.ClipGroup_37();
    this.instance.setTransform(0.85, 0.35, 0.7413, 0.7413, 0, 0, 0, 78, 73);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-56.9, -53.7, 115.6, 108.2);

  (lib.Interpolación7 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // FlashAICB
    this.instance = new lib.ClipGroup_38();
    this.instance.setTransform(0.8, 0.45, 0.7718, 0.7718, 0, 0, 0, 109.5, 114);

    this.instance_1 = new lib.Mesh_6();
    this.instance_1.setTransform(-80.4, -82.8, 4.7307, 4.7307);

    this.timeline.addTween(
      cjs.Tween.get({})
        .to({ state: [{ t: this.instance_1 }, { t: this.instance }] })
        .wait(1)
    );

    this._renderFirstFrame();
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-83.7, -87.5, 169.10000000000002, 176);

  (lib.Interpolación6 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // FlashAICB
    this.instance = new lib.ClipGroup_39();
    this.instance.setTransform(0.95, 0.1, 0.859, 0.859, 0, 0, 0, 46.5, 34.5);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-39, -29.5, 79.9, 59.2);

  (lib.Interpolación5 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // FlashAICB
    this.instance = new lib.ClipGroup_40();
    this.instance.setTransform(0.55, 0.45, 0.808, 0.808, 0, 0, 0, 57.4, 57);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-45.8, -45.6, 92.9, 92.1);

  (lib.Interpolación4 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // FlashAICB
    this.instance = new lib.ClipGroup_41();
    this.instance.setTransform(0.8, -0.05, 1, 1, 0, 0, 0, 52, 63.5);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-51.2, -63.5, 104, 127);

  (lib.Interpolación3 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // FlashAICB
    this.instance = new lib.ClipGroup_42();
    this.instance.setTransform(0.95, 0.3, 1.6091, 1.6091, 0, 0, 0, 42.5, 52);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-67.4, -83.3, 136.7, 167.3);

  (lib.Interpolación2 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // FlashAICB
    this.instance = new lib.ClipGroup_20();
    this.instance.setTransform(0.85, 0.45, 0.6182, 0.6182, 0, 0, 0, 140, 144.7);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-85.9, -89.1, 173.60000000000002, 179.2);

  (lib.Interpolación1 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.instance = new lib.ClipGroup();
    this.instance.setTransform(-0.15, 0.25, 4.8385, 4.8385, 0, 0, 0, 14.5, 16.2);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-70.3, -78.6, 141.8, 158);

  (lib.ClipGroup_1_7 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // FlashAICB
    this.instance_6 = new lib.ClipGroup_7();
    this.instance_6.setTransform(18.15, 30.75, 0.3744, 0.3744, 0, 0, 0, 51.1, 88);

    this.timeline.addTween(cjs.Tween.get(this.instance_6).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_1_7, new cjs.Rectangle(-1.1, -2.3, 38.5, 66.2), null);

  (lib.ClipGroup_43 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // FlashAICB
    this.instance_35 = new lib.ClipGroup_19();
    this.instance_35.setTransform(19.75, 32.05, 0.2327, 0.2327, 0, 0, 0, 85.1, 131.9);

    this.timeline.addTween(cjs.Tween.get(this.instance_35).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_43, new cjs.Rectangle(0, 1.3, 39.6, 61.6), null);

  (lib.Group_6_2 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_1
    this.instance_1 = new lib.ClipGroup_0_26();
    this.instance_1.setTransform(63.6, 70.5, 1, 1, 0, 0, 0, 63.6, 70.5);

    this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.Group_6_2, new cjs.Rectangle(0, 0, 127.1, 141), null);

  (lib.Group_5_1 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_1
    this.instance = new lib.ClipGroup_2_5();
    this.instance.setTransform(19.9, 16.6, 1, 1, 0, 0, 0, 19.9, 16.6);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.Group_5_1, new cjs.Rectangle(0, 0, 39.7, 33.1), null);

  (lib.Group_4_1 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_1
    this.instance = new lib.ClipGroup_4_3();
    this.instance.setTransform(24.4, 17.3, 1, 1, 0, 0, 0, 24.4, 17.3);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.Group_4_1, new cjs.Rectangle(0, 0, 48.8, 34.7), null);

  (lib.Group_3_1 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_1
    this.instance = new lib.ClipGroup_7_1();
    this.instance.setTransform(53, 52.9, 1, 1, 0, 0, 0, 53, 52.9);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.Group_3_1, new cjs.Rectangle(0, 0, 105.9, 105.9), null);

  (lib.Group_2_1 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_1
    this.instance = new lib.ClipGroup_9();
    this.instance.setTransform(21.3, 20.8, 1, 1, 0, 0, 0, 21.3, 20.8);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.Group_2_1, new cjs.Rectangle(0, 0, 42.7, 41.6), null);

  (lib.Group_1_3 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_1
    this.instance = new lib.ClipGroup_11();
    this.instance.setTransform(47.5, 38.8, 1, 1, 0, 0, 0, 47.5, 38.8);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.Group_1_3, new cjs.Rectangle(0, 0, 95.1, 77.6), null);

  (lib.ClipGroup_6_2 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_2 = new cjs.Shape();
    mask_2._off = true;
    mask_2.graphics.p(
      'Al7FWQhnh7Aai4QAbi5CRiSQBXhWBpgwQBmgtBkAAQCNAABcBWQCFB6gPDQQgQDPiWCRQi0Cui/AJIgUAAQirAAhwiGgAAzhOQgeAOgcAWQgaAVgZAyQgNAagHAVQAwgPAqgrQAWgVAYgqQAUgjAAgHQAAAAAAAAQgBgBAAAAQAAAAAAAAQgBAAAAAAQgFAAgUAKg'
    );
    mask_2.setTransform(62.3882, 76.7989);

    // Capa_3
    this.instance_1 = new lib.Group_25();
    this.instance_1.setTransform(55.4, 53.7);

    this.instance_2 = new lib.Group_1_3();
    this.instance_2.setTransform(69.3, 38.8, 1, 1, 0, 0, 0, 47.5, 38.8);
    this.instance_2.compositeOperation = 'screen';

    this.instance_3 = new lib.Group_2_1();
    this.instance_3.setTransform(54.4, 68.9, 1, 1, 0, 0, 0, 21.3, 20.8);
    this.instance_3.compositeOperation = 'screen';

    this.instance_4 = new lib.Group_3_1();
    this.instance_4.setTransform(53, 59.55, 1, 1, 0, 0, 0, 53, 52.9);
    this.instance_4.compositeOperation = 'screen';

    this.instance_5 = new lib.Group_4_1();
    this.instance_5.setTransform(50.45, 102.65, 1, 1, 0, 0, 0, 24.4, 17.3);
    this.instance_5.compositeOperation = 'screen';

    this.instance_6 = new lib.Group_5_1();
    this.instance_6.setTransform(44.85, 85.65, 1, 1, 0, 0, 0, 19.9, 16.6);
    this.instance_6.compositeOperation = 'screen';

    this.instance_7 = new lib.Group_6_2();
    this.instance_7.setTransform(80.4, 76.15, 1, 1, 0, 0, 0, 63.6, 70.5);
    this.instance_7.alpha = 0.5;
    this.instance_7.compositeOperation = 'screen';

    this.instance_8 = new lib.Group_7_2();
    this.instance_8.setTransform(16.2, 28.6);

    var maskedShapeInstanceList = [this.instance_1, this.instance_2, this.instance_3, this.instance_4, this.instance_5, this.instance_6, this.instance_7, this.instance_8];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_2;
    }

    this.timeline.addTween(
      cjs.Tween.get({})
        .to({
          state: [
            { t: this.instance_8 },
            { t: this.instance_7 },
            { t: this.instance_6 },
            { t: this.instance_5 },
            { t: this.instance_4 },
            { t: this.instance_3 },
            { t: this.instance_2 },
            { t: this.instance_1 },
          ],
        })
        .wait(1)
    );

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_6_2, new cjs.Rectangle(16.2, 29.2, 92.39999999999999, 95.3), null);

  (lib.ClipGroup_44 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_26 = new cjs.Shape();
    mask_26._off = true;
    mask_26.graphics.p('AnjIMIAAwXIPHAAIAAQXg');
    mask_26.setTransform(64.6, 74.625);

    // Capa_3
    this.instance_36 = new lib.ClipGroup_17();
    this.instance_36.setTransform(67.25, 61.9, 1, 1, 0, 0, 0, 2, 1.4);

    this.instance_37 = new lib.ClipGroup_1_0_1();
    this.instance_37.setTransform(55.05, 45.2, 1, 1, 0, 0, 0, 4.8, 2.4);

    this.instance_38 = new lib.ClipGroup_2_0();
    this.instance_38.setTransform(62.5, 64.1, 1, 1, 0, 0, 0, 4.2, 2.2);

    this.instance_39 = new lib.ClipGroup_3_0();
    this.instance_39.setTransform(47.15, 99.85, 1, 1, 0, 0, 0, 5.4, 2.1);

    this.instance_40 = new lib.ClipGroup_4_0();
    this.instance_40.setTransform(62.3, 76.75, 1, 1, 0, 0, 0, 46.1, 47.6);

    this.instance_41 = new lib.ClipGroup_5_0();
    this.instance_41.setTransform(38.95, 63.75, 1, 1, 0, 0, 0, 10.8, 11.1);

    this.instance_42 = new lib.ClipGroup_6_2();
    this.instance_42.setTransform(72, 73.3, 1, 1, 0, 0, 0, 72, 73.3);

    var maskedShapeInstanceList = [this.instance_36, this.instance_37, this.instance_38, this.instance_39, this.instance_40, this.instance_41, this.instance_42];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_26;
    }

    this.timeline.addTween(
      cjs.Tween.get({})
        .to({
          state: [
            { t: this.instance_42 },
            { t: this.instance_41 },
            { t: this.instance_40 },
            { t: this.instance_39 },
            { t: this.instance_38 },
            { t: this.instance_37 },
            { t: this.instance_36 },
          ],
        })
        .wait(1)
    );

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_44, new cjs.Rectangle(16.2, 22.3, 96.8, 104.7), null);

  (lib.Group_5_2 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_1
    this.instance_1 = new lib.ClipGroup_1_4();
    this.instance_1.setTransform(101.9, 133.8, 1, 1, 0, 0, 0, 101.9, 133.8);

    this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.Group_5_2, new cjs.Rectangle(0, 0, 203.9, 267.6), null);

  (lib.Group_4_2 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_1
    this.instance_1 = new lib.ClipGroup_3_2();
    this.instance_1.setTransform(52.5, 51.8, 1, 1, 0, 0, 0, 52.5, 51.8);

    this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.Group_4_2, new cjs.Rectangle(0, 0, 105, 103.5), null);

  (lib.Group_3_2 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_1
    this.instance_1 = new lib.ClipGroup_5_3();
    this.instance_1.setTransform(42.1, 27.2, 1, 1, 0, 0, 0, 42.1, 27.2);

    this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.Group_3_2, new cjs.Rectangle(0, 0, 84.3, 54.3), null);

  (lib.Group_2_2 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_1
    this.instance_1 = new lib.ClipGroup_7_2();
    this.instance_1.setTransform(161.3, 160.8, 1, 1, 0, 0, 0, 161.3, 160.8);

    this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.Group_2_2, new cjs.Rectangle(0, 0, 322.8, 321.5), null);

  (lib.ClipGroup_0_28 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_28 = new cjs.Shape();
    mask_28._off = true;
    mask_28.graphics.p(
      'AB2ZOQkJgGj7h2Qj5h1jSjXQjVjaiYkpQiWklgnk/QgmkuBCkhQBAkdCajiQCcjoDhiHQCOhVChgtQClguCrAAIADAAQFtABFIDKQFrDgD/G2QCCDhBBDzQA+DmAADpIAAACQAAHAjUFrQjTFrlaCVQj7BskDAAIgdgBgAjAlzQguAQABChQABCeAsB4QAlBjBZBhQBbBiA9AAQAcAAAPgaQAphKgSiNQgRiJhCiRQg6iAhbg+Qg5gngkAAQgKAAgJADg'
    );
    mask_28.setTransform(212.8424, 162.0675);

    // Capa_3
    this.instance_28 = new lib.Group_24();
    this.instance_28.setTransform(156.7, 241.45);

    this.instance_29 = new lib.Group_1_2();
    this.instance_29.setTransform(166.95, 103.3);

    this.instance_30 = new lib.Group_2_2();
    this.instance_30.setTransform(161.3, 244.65, 1, 1, 0, 0, 0, 161.3, 160.8);
    this.instance_30.alpha = 0.5;
    this.instance_30.compositeOperation = 'screen';

    this.instance_31 = new lib.Group_3_2();
    this.instance_31.setTransform(160.35, 29.1, 1, 1, 0, 0, 0, 42.1, 27.2);
    this.instance_31.compositeOperation = 'screen';

    this.instance_32 = new lib.Group_4_2();
    this.instance_32.setTransform(204.95, 207.95, 1, 1, 0, 0, 0, 52.5, 51.8);
    this.instance_32.compositeOperation = 'screen';

    this.instance_33 = new lib.Group_5_2();
    this.instance_33.setTransform(312.5, 145.95, 1, 1, 0, 0, 0, 101.9, 133.8);
    this.instance_33.alpha = 0.5;
    this.instance_33.compositeOperation = 'screen';

    this.instance_34 = new lib.Group_6_1();
    this.instance_34.setTransform(147.15, 260.65, 1, 1, 0, 0, 0, 67, 54);
    this.instance_34.compositeOperation = 'screen';

    this.instance_35 = new lib.Group_7_1();
    this.instance_35.setTransform(132.95, 190.95);

    this.instance_36 = new lib.Group_8_1();
    this.instance_36.setTransform(186.9, 30.05);

    this.instance_37 = new lib.Group_9_1();
    this.instance_37.setTransform(70.15, 0);

    var maskedShapeInstanceList = [
      this.instance_28,
      this.instance_29,
      this.instance_30,
      this.instance_31,
      this.instance_32,
      this.instance_33,
      this.instance_34,
      this.instance_35,
      this.instance_36,
      this.instance_37,
    ];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_28;
    }

    this.timeline.addTween(
      cjs.Tween.get({})
        .to({
          state: [
            { t: this.instance_37 },
            { t: this.instance_36 },
            { t: this.instance_35 },
            { t: this.instance_34 },
            { t: this.instance_33 },
            { t: this.instance_32 },
            { t: this.instance_31 },
            { t: this.instance_30 },
            { t: this.instance_29 },
            { t: this.instance_28 },
          ],
        })
        .wait(1)
    );

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_0_28, new cjs.Rectangle(70.3, 0.6, 285.2, 323), null);

  (lib.ClipGroup_45 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_27 = new cjs.Shape();
    mask_27._off = true;
    mask_27.graphics.p('A2RZPMAAAgydMAsjAAAMAAAAydg');
    mask_27.setTransform(212.85, 162.075);

    // Capa_3
    this.instance_43 = new lib.ClipGroup_0_28();
    this.instance_43.setTransform(207.2, 202.7, 1, 1, 0, 0, 0, 207.2, 202.7);

    var maskedShapeInstanceList = [this.instance_43];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_27;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_43).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_45, new cjs.Rectangle(70.3, 0.6, 285.2, 323), null);

  (lib.Group_0_2 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_1
    this.instance_2 = new lib.ClipGroup_4_4();
    this.instance_2.setTransform(24.3, 4.2, 1, 1, 0, 0, 0, 24.3, 4.2);

    this.timeline.addTween(cjs.Tween.get(this.instance_2).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.Group_0_2, new cjs.Rectangle(-0.1, -0.4, 48.9, 9.1), null);

  (lib.ClipGroup_3_5 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_5 = new cjs.Shape();
    mask_5._off = true;
    mask_5.graphics.p('ArnPxIAA/hIXPAAIAAfhg');
    mask_5.setTransform(74.425, 100.9);

    // Capa_3
    this.instance_4 = new lib.Group_0_2();
    this.instance_4.setTransform(38.35, 38.9, 1, 1, 0, 0, 0, 24.3, 4.2);
    this.instance_4.alpha = 0.5;

    var maskedShapeInstanceList = [this.instance_4];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_5;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_4).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_3_5, new cjs.Rectangle(13.9, 34.3, 49, 9.100000000000001), null);

  (lib.ClipGroup_46 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_2 (mask)
    var mask_28 = new cjs.Shape();
    mask_28._off = true;
    mask_28.graphics.p('ArnPxIAA/hIXPAAIAAfhg');
    mask_28.setTransform(74.475, 101.65);

    // Capa_3
    this.instance_44 = new lib.ClipGroup_3_5();
    this.instance_44.setTransform(74.45, 101.65, 1, 1, 0, 0, 0, 74.4, 100.9);

    this.instance_45 = new lib.ClipGroup_1_6();
    this.instance_45.setTransform(47.1, 15.2, 1, 1, 0, 0, 0, 18.7, 6.7);

    this.instance_46 = new lib.ClipGroup_2_3();
    this.instance_46.setTransform(74.5, 101.5, 1, 1, 0, 0, 0, 74.5, 101.5);

    var maskedShapeInstanceList = [this.instance_44, this.instance_45, this.instance_46];

    for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask_28;
    }

    this.timeline.addTween(
      cjs.Tween.get({})
        .to({ state: [{ t: this.instance_46 }, { t: this.instance_45 }, { t: this.instance_44 }] })
        .wait(1)
    );

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_46, new cjs.Rectangle(0.1, 0.8, 148.8, 201.79999999999998), null);

  (lib.Interpolación9 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // FlashAICB
    this.instance = new lib.ClipGroup_46();
    this.instance.setTransform(0.55, -0.15, 0.7519, 0.7519, 0, 0, 0, 74.5, 101.5);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-55.4, -76.4, 112, 152.60000000000002);

  (lib.ClipGroup_4_5 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // FlashAICB
    this.instance_4 = new lib.ClipGroup_44();
    this.instance_4.setTransform(21.7, 19.85, 0.4208, 0.4208, 0, 0, 0, 71.9, 73.3);

    this.timeline.addTween(cjs.Tween.get(this.instance_4).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_4_5, new cjs.Rectangle(-8.5, -11, 60.6, 61.7), null);

  (lib.ClipGroup_3_6 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_4
    this.instance_5 = new lib.ClipGroup_44();
    this.instance_5.setTransform(22.05, 19.85, 0.4208, 0.4208, -14.9978, 0, 0, 71.9, 73.2);

    this.timeline.addTween(cjs.Tween.get(this.instance_5).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_3_6, new cjs.Rectangle(-14.3, -15.4, 73.7, 71.2), null);

  (lib.ClipGroup_2_7 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // FlashAICB
    this.instance_6 = new lib.ClipGroup_45();
    this.instance_6.setTransform(19.7, 29.1, 0.1438, 0.1438, 0, 0, 0, 207.2, 202.7);

    this.timeline.addTween(cjs.Tween.get(this.instance_6).wait(1));

    this._renderFirstFrame();
  }).prototype = getMCSymbolPrototype(lib.ClipGroup_2_7, new cjs.Rectangle(-10.1, 0, 59.6, 58.3), null);

  // stage content:
  (lib.confeti = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // _Clip_Group_
    this.instance = new lib.ClipGroup_43();
    this.instance.setTransform(705.4, 948.05, 1.33, 1.33, 0, 0, 0, 20.9, 32);
    this.instance.alpha = 0;
    this.instance._off = true;

    this.timeline.addTween(
      cjs.Tween.get(this.instance)
        .wait(5)
        .to({ _off: false }, 0)
        .wait(1)
        .to({ regX: 19.8, regY: 32.1, scaleX: 1.9613, scaleY: 1.9613, x: 760.25, y: 833.15, alpha: 0.1856 }, 0)
        .wait(1)
        .to({ scaleX: 2.5807, scaleY: 2.5807, x: 815.5, y: 720.3, alpha: 0.3678 }, 0)
        .wait(1)
        .to({ scaleX: 3.1766, scaleY: 3.1766, x: 868.65, y: 611.75, alpha: 0.543 }, 0)
        .wait(1)
        .to({ scaleX: 3.7383, scaleY: 3.7383, x: 918.75, y: 509.45, alpha: 0.7082 }, 0)
        .wait(1)
        .to({ scaleX: 4.2578, scaleY: 4.2578, x: 965.1, y: 414.8, alpha: 0.8609 }, 0)
        .wait(1)
        .to({ regX: 20.8, regY: 31.9, scaleX: 4.7307, scaleY: 4.7307, x: 1012.3, y: 327.95, alpha: 1 }, 0)
        .to({ y: 368.2 }, 23, cjs.Ease.cubicIn)
        .to({ x: 985.65, y: 2789.05, alpha: 0 }, 15, cjs.Ease.quadOut)
        .to({ _off: true }, 1)
        .wait(11)
    );

    // _Clip_Group__1
    this.instance_1 = new lib.ClipGroup_1_7();
    this.instance_1.setTransform(453.65, 1360.2, 1.33, 1.33, 0, 0, 0, 18.6, 32.2);
    this.instance_1.alpha = 0;
    this.instance_1._off = true;

    this.timeline.addTween(
      cjs.Tween.get(this.instance_1)
        .wait(8)
        .to({ _off: false }, 0)
        .wait(1)
        .to({ regX: 18.1, regY: 30.4, scaleX: 1.9613, scaleY: 1.9613, x: 390.2, y: 1437.15, alpha: 0.1856 }, 0)
        .wait(1)
        .to({ scaleX: 2.5807, scaleY: 2.5807, x: 328.6, y: 1515.05, alpha: 0.3678 }, 0)
        .wait(1)
        .to({ scaleX: 3.1766, scaleY: 3.1766, x: 269.35, y: 1590, alpha: 0.543 }, 0)
        .wait(1)
        .to({ scaleX: 3.7383, scaleY: 3.7383, x: 213.5, y: 1660.7, alpha: 0.7082 }, 0)
        .wait(1)
        .to({ scaleX: 4.2578, scaleY: 4.2578, x: 161.85, y: 1726.05, alpha: 0.8609 }, 0)
        .wait(1)
        .to({ regX: 18.6, regY: 32.1, scaleX: 4.7307, scaleY: 4.7307, x: 117.25, y: 1793.8, alpha: 1 }, 0)
        .to({ y: 1834.05 }, 23, cjs.Ease.cubicIn)
        .to({ x: 90.6, y: 2790, alpha: 0 }, 19, cjs.Ease.quadOut)
        .to({ _off: true }, 1)
        .wait(4)
    );

    // _Mesh__35
    this.instance_2 = new lib.Interpolación1('synched', 0);
    this.instance_2.setTransform(631.9, 1228.45, 0.2811, 0.2811, 0, 0, 0, 0.2, 0);
    this.instance_2.alpha = 0;

    this.timeline.addTween(
      cjs.Tween.get(this.instance_2)
        .wait(1)
        .to({ regX: 0.3, regY: -0.6, scaleX: 0.4146, scaleY: 0.4146, x: 654.05, y: 1246.25, alpha: 0.1856 }, 0)
        .wait(1)
        .to({ scaleX: 0.5455, scaleY: 0.5455, x: 675.8, y: 1263.85, alpha: 0.3678 }, 0)
        .wait(1)
        .to({ scaleX: 0.6715, scaleY: 0.6715, x: 696.7, y: 1280.85, alpha: 0.543 }, 0)
        .wait(1)
        .to({ scaleX: 0.7902, scaleY: 0.7902, x: 716.4, y: 1296.85, alpha: 0.7082 }, 0)
        .wait(1)
        .to({ scaleX: 0.9, scaleY: 0.9, x: 734.6, y: 1311.6, alpha: 0.8609 }, 0)
        .wait(1)
        .to({ regX: 0, regY: 0, scaleX: 1, scaleY: 1, x: 751.15, y: 1325.7, alpha: 1 }, 0)
        .to({ y: 1365.95 }, 23, cjs.Ease.cubicIn)
        .to({ x: 724.5, y: 2788.1, alpha: 0 }, 15, cjs.Ease.quadOut)
        .to({ _off: true }, 1)
        .wait(16)
    );

    // _Mesh__1_5
    this.instance_3 = new lib.Interpolación2('synched', 0);
    this.instance_3.setTransform(452.15, 1010.05, 0.2811, 0.2811, 0, 0, 0, 0.2, 0);
    this.instance_3.alpha = 0;
    this.instance_3._off = true;

    this.timeline.addTween(
      cjs.Tween.get(this.instance_3)
        .wait(2)
        .to({ _off: false }, 0)
        .wait(1)
        .to({ regX: 0.8, regY: 0.5, scaleX: 0.4146, scaleY: 0.4146, x: 389.2, y: 924.6, alpha: 0.1856 }, 0)
        .wait(1)
        .to({ scaleX: 0.5455, scaleY: 0.5455, x: 327.3, y: 840.65, alpha: 0.3678 }, 0)
        .wait(1)
        .to({ scaleX: 0.6715, scaleY: 0.6715, x: 267.75, y: 759.95, alpha: 0.543 }, 0)
        .wait(1)
        .to({ scaleX: 0.7902, scaleY: 0.7902, x: 211.6, y: 683.85, alpha: 0.7082 }, 0)
        .wait(1)
        .to({ scaleX: 0.9, scaleY: 0.9, x: 159.6, y: 613.45, alpha: 0.8609 }, 0)
        .wait(1)
        .to({ regX: 0, regY: 0, scaleX: 1, scaleY: 1, x: 111.8, y: 548.9, alpha: 1 }, 0)
        .to({ y: 589.15 }, 23, cjs.Ease.cubicIn)
        .to({ x: 85.15, y: 2788.1, alpha: 0 }, 15, cjs.Ease.quadOut)
        .to({ _off: true }, 1)
        .wait(14)
    );

    // _Clip_Group__2
    this.instance_4 = new lib.ClipGroup_2_7();
    this.instance_4.setTransform(542.75, 1039.7, 1.33, 1.33, 0, 0, 0, 20.6, 22.9);
    this.instance_4.alpha = 0;

    this.timeline.addTween(
      cjs.Tween.get(this.instance_4)
        .wait(1)
        .to({ regX: 20.5, regY: 23.3, scaleX: 1.9613, scaleY: 1.9613, x: 522.35, y: 968.95, alpha: 0.1856 }, 0)
        .wait(1)
        .to({ scaleX: 2.5807, scaleY: 2.5807, x: 502.45, y: 899, alpha: 0.3678 }, 0)
        .wait(1)
        .to({ scaleX: 3.1766, scaleY: 3.1766, x: 483.3, y: 831.7, alpha: 0.543 }, 0)
        .wait(1)
        .to({ scaleX: 3.7383, scaleY: 3.7383, x: 465.35, y: 768.3, alpha: 0.7082 }, 0)
        .wait(1)
        .to({ scaleX: 4.2578, scaleY: 4.2578, x: 448.65, y: 709.65, alpha: 0.8609 }, 0)
        .wait(1)
        .to({ regY: 22.9, scaleX: 4.7307, scaleY: 4.7307, x: 433.75, y: 654.4, alpha: 1 }, 0)
        .to({ y: 694.65 }, 23, cjs.Ease.cubicIn)
        .to({ x: 407.1, y: 2787.9, alpha: 0 }, 21, cjs.Ease.quadOut)
        .to({ _off: true }, 1)
        .wait(10)
    );

    // _Clip_Group__3
    this.instance_5 = new lib.ClipGroup_3_6();
    this.instance_5.setTransform(579.75, 1292.15, 1.33, 1.33, 0, 0, 0, 17.2, 20.7);
    this.instance_5.alpha = 0;

    this.timeline.addTween(
      cjs.Tween.get(this.instance_5)
        .wait(1)
        .to({ regX: 18.6, regY: 22.4, scaleX: 1.9613, scaleY: 1.9613, x: 579.85, y: 1343.75, alpha: 0.1856 }, 0)
        .wait(1)
        .to({ scaleX: 2.5807, scaleY: 2.5807, x: 578.15, y: 1392.15, alpha: 0.3678 }, 0)
        .wait(1)
        .to({ scaleX: 3.1766, scaleY: 3.1766, x: 576.55, y: 1438.75, alpha: 0.543 }, 0)
        .wait(1)
        .to({ scaleX: 3.7383, scaleY: 3.7383, x: 575.05, y: 1482.7, alpha: 0.7082 }, 0)
        .wait(1)
        .to({ scaleX: 4.2578, scaleY: 4.2578, x: 573.6, y: 1523.35, alpha: 0.8609 }, 0)
        .wait(1)
        .to({ regX: 17.2, regY: 20.7, scaleX: 4.7307, scaleY: 4.7307, x: 565.7, y: 1552.3, alpha: 1 }, 0)
        .to({ y: 1592.55 }, 23, cjs.Ease.cubicIn)
        .to({ x: 539.05, y: 2783.65, alpha: 0 }, 15, cjs.Ease.quadOut)
        .to({ _off: true }, 1)
        .wait(16)
    );

    // _Clip_Group__4
    this.instance_6 = new lib.ClipGroup_4_5();
    this.instance_6.setTransform(654.85, 1040.35, 1.33, 1.33, 0, 0, 0, 18.5, 19.9);
    this.instance_6.alpha = 0;
    this.instance_6._off = true;

    this.timeline.addTween(
      cjs.Tween.get(this.instance_6)
        .wait(5)
        .to({ _off: false }, 0)
        .wait(1)
        .to({ regX: 17.7, regY: 21.3, scaleX: 1.9613, scaleY: 1.9613, x: 686.3, y: 971.9, alpha: 0.1856 }, 0)
        .wait(1)
        .to({ scaleX: 2.5807, scaleY: 2.5807, x: 718.25, y: 902.85, alpha: 0.3678 }, 0)
        .wait(1)
        .to({ scaleX: 3.1766, scaleY: 3.1766, x: 748.95, y: 836.45, alpha: 0.543 }, 0)
        .wait(1)
        .to({ scaleX: 3.7383, scaleY: 3.7383, x: 777.8, y: 773.95, alpha: 0.7082 }, 0)
        .wait(1)
        .to({ scaleX: 4.2578, scaleY: 4.2578, x: 804.6, y: 716.05, alpha: 0.8609 }, 0)
        .wait(1)
        .to({ regX: 18.4, regY: 19.9, scaleX: 4.7307, scaleY: 4.7307, x: 832.55, y: 656.75, alpha: 1 }, 0)
        .to({ y: 697 }, 23, cjs.Ease.cubicIn)
        .to({ x: 805.9, y: 2783.85, alpha: 0 }, 15, cjs.Ease.quadOut)
        .to({ _off: true }, 1)
        .wait(11)
    );

    // _Mesh__2_1
    this.instance_7 = new lib.Interpolación3('synched', 0);
    this.instance_7.setTransform(553.6, 973.7, 0.2811, 0.2811, 0, 0, 0, 0, 0.2);
    this.instance_7.alpha = 0;
    this.instance_7._off = true;

    this.timeline.addTween(
      cjs.Tween.get(this.instance_7)
        .wait(14)
        .to({ _off: false }, 0)
        .wait(1)
        .to({ regX: 0.3, regY: -0.2, scaleX: 0.4146, scaleY: 0.4146, x: 538.65, y: 870.6, alpha: 0.1856 }, 0)
        .wait(1)
        .to({ scaleX: 0.5455, scaleY: 0.5455, x: 524, y: 769.65, alpha: 0.3678 }, 0)
        .wait(1)
        .to({ scaleX: 0.6715, scaleY: 0.6715, x: 509.85, y: 672.45, alpha: 0.543 }, 0)
        .wait(1)
        .to({ scaleX: 0.7902, scaleY: 0.7902, x: 496.55, y: 580.9, alpha: 0.7082 }, 0)
        .wait(1)
        .to({ scaleX: 0.9, scaleY: 0.9, x: 484.2, y: 496.15, alpha: 0.8609 }, 0)
        .wait(1)
        .to({ regX: 0, regY: 0, scaleX: 1, scaleY: 1, x: 472.75, y: 419.5, alpha: 1 }, 0)
        .to({ y: 459.75 }, 23, cjs.Ease.cubicIn)
        .to({ x: 446.1, y: 2788.1, alpha: 0 }, 15, cjs.Ease.quadOut)
        .to({ _off: true }, 1)
        .wait(2)
    );

    // _Mesh__3_0
    this.instance_8 = new lib.Interpolación4('synched', 0);
    this.instance_8.setTransform(566.8, 1344.65, 0.2811, 0.2811, 0, 0, 0, 0.2, 0.2);
    this.instance_8.alpha = 0;

    this.timeline.addTween(
      cjs.Tween.get(this.instance_8)
        .wait(1)
        .to({ regX: 0.5, regY: 0, scaleX: 0.4146, scaleY: 0.4146, x: 558.15, y: 1417.75, alpha: 0.1856 }, 0)
        .wait(1)
        .to({ scaleX: 0.5455, scaleY: 0.5455, x: 549.55, y: 1489.5, alpha: 0.3678 }, 0)
        .wait(1)
        .to({ scaleX: 0.6715, scaleY: 0.6715, x: 541.35, y: 1558.55, alpha: 0.543 }, 0)
        .wait(1)
        .to({ scaleX: 0.7902, scaleY: 0.7902, x: 533.6, y: 1623.65, alpha: 0.7082 }, 0)
        .wait(1)
        .to({ scaleX: 0.9, scaleY: 0.9, x: 526.4, y: 1683.85, alpha: 0.8609 }, 0)
        .wait(1)
        .to({ regX: 0, scaleX: 1, scaleY: 1, x: 519.6, y: 1738.9, alpha: 1 }, 0)
        .to({ y: 1779.15 }, 23, cjs.Ease.cubicIn)
        .to({ x: 492.95, y: 2788.1, alpha: 0 }, 15, cjs.Ease.quadOut)
        .to({ _off: true }, 1)
        .wait(16)
    );

    // _Mesh__4
    this.instance_9 = new lib.Interpolación5('synched', 0);
    this.instance_9.setTransform(573.5, 1221.75, 0.2811, 0.2811, 0, 0, 0, 0.2, 0.2);
    this.instance_9.alpha = 0;
    this.instance_9._off = true;

    this.timeline.addTween(
      cjs.Tween.get(this.instance_9)
        .wait(10)
        .to({ _off: false }, 0)
        .wait(1)
        .to({ regX: 0.5, regY: 0.1, scaleX: 0.4146, scaleY: 0.4146, x: 568, y: 1236.55, alpha: 0.1856 }, 0)
        .wait(1)
        .to({ scaleX: 0.5455, scaleY: 0.5455, x: 562.55, y: 1251.1, alpha: 0.3678 }, 0)
        .wait(1)
        .to({ scaleX: 0.6715, scaleY: 0.6715, x: 557.4, y: 1265.1, alpha: 0.543 }, 0)
        .wait(1)
        .to({ scaleX: 0.7902, scaleY: 0.7902, x: 552.45, y: 1278.35, alpha: 0.7082 }, 0)
        .wait(1)
        .to({ scaleX: 0.9, scaleY: 0.9, x: 547.85, y: 1290.55, alpha: 0.8609 }, 0)
        .wait(1)
        .to({ regX: 0, regY: 0, scaleX: 1, scaleY: 1, x: 543.45, y: 1301.8, alpha: 1 }, 0)
        .to({ y: 1342.05 }, 23, cjs.Ease.cubicIn)
        .to({ x: 516.8, y: 2788.1, alpha: 0 }, 15, cjs.Ease.quadOut)
        .to({ _off: true }, 1)
        .wait(6)
    );

    // _Mesh__5
    this.instance_10 = new lib.Interpolación6('synched', 0);
    this.instance_10.setTransform(603.15, 1023.7, 0.2811, 0.2811, 0, 0, 0, 0.2, 0);
    this.instance_10.alpha = 0;

    this.timeline.addTween(
      cjs.Tween.get(this.instance_10)
        .wait(1)
        .to({ regX: 0.6, regY: 0.1, scaleX: 0.4146, scaleY: 0.4146, x: 611.8, y: 944.6, alpha: 0.1856 }, 0)
        .wait(1)
        .to({ scaleX: 0.5455, scaleY: 0.5455, x: 620.2, y: 866.95, alpha: 0.3678 }, 0)
        .wait(1)
        .to({ scaleX: 0.6715, scaleY: 0.6715, x: 628.25, y: 792.25, alpha: 0.543 }, 0)
        .wait(1)
        .to({ scaleX: 0.7902, scaleY: 0.7902, x: 635.85, y: 721.9, alpha: 0.7082 }, 0)
        .wait(1)
        .to({ scaleX: 0.9, scaleY: 0.9, x: 642.95, y: 656.75, alpha: 0.8609 }, 0)
        .wait(1)
        .to({ regX: 0, regY: 0, scaleX: 1, scaleY: 1, x: 648.95, y: 597.4, alpha: 1 }, 0)
        .to({ y: 637.65 }, 23, cjs.Ease.cubicIn)
        .to({ x: 622.3, y: 2788.1, alpha: 0 }, 15, cjs.Ease.quadOut)
        .to({ _off: true }, 1)
        .wait(16)
    );

    // _Mesh__6
    this.instance_11 = new lib.Interpolación7('synched', 0);
    this.instance_11.setTransform(476.8, 1048.75, 0.2811, 0.2811);
    this.instance_11.alpha = 0;
    this.instance_11._off = true;

    this.timeline.addTween(
      cjs.Tween.get(this.instance_11)
        .wait(4)
        .to({ _off: false }, 0)
        .wait(1)
        .to({ regX: 0.6, regY: 0.1, scaleX: 0.4146, scaleY: 0.4146, x: 425.55, y: 981.55, alpha: 0.1856 }, 0)
        .wait(1)
        .to({ scaleX: 0.5455, scaleY: 0.5455, x: 375.15, y: 915.55, alpha: 0.3678 }, 0)
        .wait(1)
        .to({ scaleX: 0.6715, scaleY: 0.6715, x: 326.65, y: 852.1, alpha: 0.543 }, 0)
        .wait(1)
        .to({ scaleX: 0.7902, scaleY: 0.7902, x: 280.9, y: 792.35, alpha: 0.7082 }, 0)
        .wait(1)
        .to({ scaleX: 0.9, scaleY: 0.9, x: 238.65, y: 737, alpha: 0.8609 }, 0)
        .wait(1)
        .to({ regX: 0, regY: 0, scaleX: 1, scaleY: 1, x: 199.55, y: 686.55, alpha: 1 }, 0)
        .to({ y: 726.8 }, 23, cjs.Ease.cubicIn)
        .to({ x: 172.9, y: 2788.1, alpha: 0 }, 15, cjs.Ease.quadOut)
        .to({ _off: true }, 1)
        .wait(12)
    );

    // _Mesh__7
    this.instance_12 = new lib.Interpolación8('synched', 0);
    this.instance_12.setTransform(508.8, 925.9, 0.2811, 0.2811, 0, 0, 0, 0.2, 0.2);
    this.instance_12.alpha = 0;
    this.instance_12._off = true;

    this.timeline.addTween(
      cjs.Tween.get(this.instance_12)
        .wait(8)
        .to({ _off: false }, 0)
        .wait(1)
        .to({ regX: 0.6, regY: 0.1, scaleX: 0.4146, scaleY: 0.4146, x: 472.65, y: 800.25, alpha: 0.1856 }, 0)
        .wait(1)
        .to({ scaleX: 0.5455, scaleY: 0.5455, x: 437.1, y: 677.05, alpha: 0.3678 }, 0)
        .wait(1)
        .to({ scaleX: 0.6715, scaleY: 0.6715, x: 402.9, y: 558.5, alpha: 0.543 }, 0)
        .wait(1)
        .to({ scaleX: 0.7902, scaleY: 0.7902, x: 370.6, y: 446.8, alpha: 0.7082 }, 0)
        .wait(1)
        .to({ scaleX: 0.9, scaleY: 0.9, x: 340.85, y: 343.4, alpha: 0.8609 }, 0)
        .wait(1)
        .to({ regX: 0, regY: 0, scaleX: 1, scaleY: 1, x: 313.3, y: 249.45, alpha: 1 }, 0)
        .to({ y: 289.7 }, 23, cjs.Ease.cubicIn)
        .to({ x: 286.65, y: 2788.1, alpha: 0 }, 15, cjs.Ease.quadOut)
        .to({ _off: true }, 1)
        .wait(8)
    );

    // Capa_16
    this.instance_13 = new lib.Interpolación9('synched', 0);
    this.instance_13.setTransform(635.45, 954.3, 0.2811, 0.2811, 0, 0, 0, 0, 0.2);
    this.instance_13.alpha = 0;

    this.timeline.addTween(
      cjs.Tween.get(this.instance_13)
        .wait(1)
        .to({ regX: 0.5, regY: 0, scaleX: 0.4146, scaleY: 0.4146, x: 659.45, y: 842.1, alpha: 0.1856 }, 0)
        .wait(1)
        .to({ scaleX: 0.5455, scaleY: 0.5455, x: 682.9, y: 732.1, alpha: 0.3678 }, 0)
        .wait(1)
        .to({ scaleX: 0.6715, scaleY: 0.6715, x: 705.5, y: 626.25, alpha: 0.543 }, 0)
        .wait(1)
        .to({ scaleX: 0.7902, scaleY: 0.7902, x: 726.8, y: 526.5, alpha: 0.7082 }, 0)
        .wait(1)
        .to({ scaleX: 0.9, scaleY: 0.9, x: 746.45, y: 434.2, alpha: 0.8609 }, 0)
        .wait(1)
        .to({ regX: 0, scaleX: 1, scaleY: 1, x: 763.9, y: 350.45, alpha: 1 }, 0)
        .to({ y: 390.7 }, 23, cjs.Ease.cubicIn)
        .to({ x: 737.25, y: 2788.15, alpha: 0 }, 11, cjs.Ease.quadOut)
        .to({ _off: true }, 5)
        .wait(16)
    );

    // _Mesh__8
    this.instance_14 = new lib.Interpolación10('synched', 0);
    this.instance_14.setTransform(710.35, 1028.3, 0.2811, 0.2811, 0, 0, 0, 0.2, 0.2);
    this.instance_14.alpha = 0;

    this.timeline.addTween(
      cjs.Tween.get(this.instance_14)
        .wait(1)
        .to({ regX: 0.5, regY: 0.1, scaleX: 0.4146, scaleY: 0.4146, x: 769.85, y: 951.3, alpha: 0.1856 }, 0)
        .wait(1)
        .to({ scaleX: 0.5455, scaleY: 0.5455, x: 828.1, y: 875.75, alpha: 0.3678 }, 0)
        .wait(1)
        .to({ scaleX: 0.6715, scaleY: 0.6715, x: 884.25, y: 803.05, alpha: 0.543 }, 0)
        .wait(1)
        .to({ scaleX: 0.7902, scaleY: 0.7902, x: 937.1, y: 734.6, alpha: 0.7082 }, 0)
        .wait(1)
        .to({ scaleX: 0.9, scaleY: 0.9, x: 986, y: 671.25, alpha: 0.8609 }, 0)
        .wait(1)
        .to({ regX: 0, regY: 0, scaleX: 1, scaleY: 1, x: 1030.25, y: 613.7, alpha: 1 }, 0)
        .to({ y: 653.95 }, 23, cjs.Ease.cubicIn)
        .to({ x: 1003.6, y: 2788.1, alpha: 0 }, 28, cjs.Ease.quadOut)
        .to({ _off: true }, 1)
        .wait(3)
    );

    // _Mesh__9
    this.instance_15 = new lib.Interpolación11('synched', 0);
    this.instance_15.setTransform(515.45, 1299.6, 0.2811, 0.2811, 0, 0, 0, 0.2, 0);
    this.instance_15.alpha = 0;
    this.instance_15._off = true;

    this.timeline.addTween(
      cjs.Tween.get(this.instance_15)
        .wait(2)
        .to({ _off: false }, 0)
        .wait(1)
        .to({ regX: 0.7, regY: 0.3, scaleX: 0.4146, scaleY: 0.4146, x: 482.5, y: 1351.5, alpha: 0.1856 }, 0)
        .wait(1)
        .to({ scaleX: 0.5455, scaleY: 0.5455, x: 450.1, y: 1402.4, alpha: 0.3678 }, 0)
        .wait(1)
        .to({ scaleX: 0.6715, scaleY: 0.6715, x: 418.85, y: 1451.4, alpha: 0.543 }, 0)
        .wait(1)
        .to({ scaleX: 0.7902, scaleY: 0.7902, x: 389.45, y: 1497.55, alpha: 0.7082 }, 0)
        .wait(1)
        .to({ scaleX: 0.9, scaleY: 0.9, x: 362.25, y: 1540.2, alpha: 0.8609 }, 0)
        .wait(1)
        .to({ regX: 0, regY: 0, scaleX: 1, scaleY: 1, x: 337, y: 1578.8, alpha: 1 }, 0)
        .to({ y: 1619.05 }, 23, cjs.Ease.cubicIn)
        .to({ x: 310.35, y: 2788.1, alpha: 0 }, 10, cjs.Ease.quadOut)
        .to({ _off: true }, 6)
        .wait(14)
    );

    // _Mesh__10
    this.instance_16 = new lib.Interpolación12('synched', 0);
    this.instance_16.setTransform(704.85, 1268.5, 0.2811, 0.2811, 0, 0, 0, 0.2, 0.2);
    this.instance_16.alpha = 0;

    this.timeline.addTween(
      cjs.Tween.get(this.instance_16)
        .wait(1)
        .to({ regX: 0.6, regY: 0.1, scaleX: 0.4146, scaleY: 0.4146, x: 761.75, y: 1305.5, alpha: 0.1856 }, 0)
        .wait(1)
        .to({ scaleX: 0.5455, scaleY: 0.5455, x: 817.55, y: 1341.8, alpha: 0.3678 }, 0)
        .wait(1)
        .to({ scaleX: 0.6715, scaleY: 0.6715, x: 871.15, y: 1376.75, alpha: 0.543 }, 0)
        .wait(1)
        .to({ scaleX: 0.7902, scaleY: 0.7902, x: 921.7, y: 1409.75, alpha: 0.7082 }, 0)
        .wait(1)
        .to({ scaleX: 0.9, scaleY: 0.9, x: 968.45, y: 1440.2, alpha: 0.8609 }, 0)
        .wait(1)
        .to({ regX: 0, regY: 0, scaleX: 1, scaleY: 1, x: 1010.65, y: 1468.05, alpha: 1 }, 0)
        .to({ y: 1508.3 }, 23, cjs.Ease.cubicIn)
        .to({ x: 984, y: 2788.1, alpha: 0 }, 15, cjs.Ease.quadOut)
        .to({ _off: true }, 1)
        .wait(16)
    );

    // Capa_20
    this.instance_17 = new lib.Interpolación13('synched', 0);
    this.instance_17.setTransform(475.75, 1233.9, 0.2811, 0.2811, 0, 0, 0, 0.2, 0);
    this.instance_17.alpha = 0;
    this.instance_17._off = true;

    this.timeline.addTween(
      cjs.Tween.get(this.instance_17)
        .wait(3)
        .to({ _off: false }, 0)
        .wait(1)
        .to({ regX: -0.3, scaleX: 0.4146, scaleY: 0.4146, x: 423.6, y: 1254.5, alpha: 0.1856 }, 0)
        .wait(1)
        .to({ scaleX: 0.5455, scaleY: 0.5455, x: 372.5, y: 1274.75, alpha: 0.3678 }, 0)
        .wait(1)
        .to({ scaleX: 0.6715, scaleY: 0.6715, x: 323.4, y: 1294.25, alpha: 0.543 }, 0)
        .wait(1)
        .to({ scaleX: 0.7902, scaleY: 0.7902, x: 277.05, y: 1312.6, alpha: 0.7082 }, 0)
        .wait(1)
        .to({ scaleX: 0.9, scaleY: 0.9, x: 234.3, y: 1329.55, alpha: 0.8609 }, 0)
        .wait(1)
        .to({ regX: 0, scaleX: 1, scaleY: 1, x: 195.8, y: 1345.05, alpha: 1 }, 0)
        .to({ y: 1385.3 }, 23, cjs.Ease.cubicIn)
        .to({ x: 169.15, y: 2788.15, alpha: 0 }, 15, cjs.Ease.quadOut)
        .to({ _off: true }, 1)
        .wait(13)
    );

    // _Mesh__11
    this.instance_18 = new lib.Interpolación14('synched', 0);
    this.instance_18.setTransform(701.05, 1202.25, 0.2811, 0.2811, 0, 0, 0, 0.2, 0);
    this.instance_18.alpha = 0;

    this.timeline.addTween(
      cjs.Tween.get(this.instance_18)
        .wait(1)
        .to({ regX: 0.7, scaleX: 0.4146, scaleY: 0.4146, x: 756.2, y: 1207.85, alpha: 0.1856 }, 0)
        .wait(1)
        .to({ scaleX: 0.5455, scaleY: 0.5455, x: 810.2, y: 1213.35, alpha: 0.3678 }, 0)
        .wait(1)
        .to({ scaleX: 0.6715, scaleY: 0.6715, x: 862.15, y: 1218.65, alpha: 0.543 }, 0)
        .wait(1)
        .to({ scaleX: 0.7902, scaleY: 0.7902, x: 911.1, y: 1223.65, alpha: 0.7082 }, 0)
        .wait(1)
        .to({ scaleX: 0.9, scaleY: 0.9, x: 956.4, y: 1228.25, alpha: 0.8609 }, 0)
        .wait(1)
        .to({ regX: 0, scaleX: 1, scaleY: 1, x: 997.15, y: 1232.5, alpha: 1 }, 0)
        .to({ y: 1272.75 }, 23, cjs.Ease.cubicIn)
        .to({ x: 970.5, y: 2788.1, alpha: 0 }, 15, cjs.Ease.quadOut)
        .to({ _off: true }, 1)
        .wait(16)
    );

    // _Mesh__12
    this.instance_19 = new lib.Interpolación15('synched', 0);
    this.instance_19.setTransform(571.85, 919.2, 0.2811, 0.2811, 0, 0, 0, 0.2, 0);
    this.instance_19.alpha = 0;

    this.timeline.addTween(
      cjs.Tween.get(this.instance_19)
        .wait(1)
        .to({ regX: 0.6, regY: 0.1, scaleX: 0.4146, scaleY: 0.4146, x: 565.65, y: 790.5, alpha: 0.1856 }, 0)
        .wait(1)
        .to({ scaleX: 0.5455, scaleY: 0.5455, x: 559.45, y: 664.2, alpha: 0.3678 }, 0)
        .wait(1)
        .to({ scaleX: 0.6715, scaleY: 0.6715, x: 553.45, y: 542.7, alpha: 0.543 }, 0)
        .wait(1)
        .to({ scaleX: 0.7902, scaleY: 0.7902, x: 547.85, y: 428.2, alpha: 0.7082 }, 0)
        .wait(1)
        .to({ scaleX: 0.9, scaleY: 0.9, x: 542.65, y: 322.25, alpha: 0.8609 }, 0)
        .wait(1)
        .to({ regX: 0, regY: 0, scaleX: 1, scaleY: 1, x: 537.55, y: 225.75, alpha: 1 }, 0)
        .to({ y: 266 }, 23, cjs.Ease.cubicIn)
        .to({ x: 510.9, y: 2788.1, alpha: 0 }, 20, cjs.Ease.quadOut)
        .to({ _off: true }, 1)
        .wait(11)
    );

    // Capa_23
    this.instance_20 = new lib.Interpolación16('synched', 0);
    this.instance_20.setTransform(689.6, 1130.1, 0.2811, 0.2811, 0, 0, 0, 0, 0.2);
    this.instance_20.alpha = 0;

    this.timeline.addTween(
      cjs.Tween.get(this.instance_20)
        .wait(1)
        .to({ regX: -0.4, regY: 0.3, scaleX: 0.4146, scaleY: 0.4146, x: 738.95, y: 1101.45, alpha: 0.1856 }, 0)
        .wait(1)
        .to({ scaleX: 0.5455, scaleY: 0.5455, x: 787.55, y: 1073.35, alpha: 0.3678 }, 0)
        .wait(1)
        .to({ scaleX: 0.6715, scaleY: 0.6715, x: 834.25, y: 1046.35, alpha: 0.543 }, 0)
        .wait(1)
        .to({ scaleX: 0.7902, scaleY: 0.7902, x: 878.3, y: 1020.9, alpha: 0.7082 }, 0)
        .wait(1)
        .to({ scaleX: 0.9, scaleY: 0.9, x: 919, y: 997.3, alpha: 0.8609 }, 0)
        .wait(1)
        .to({ regX: 0, regY: 0, scaleX: 1, scaleY: 1, x: 956.5, y: 975.8, alpha: 1 }, 0)
        .to({ y: 1016.05 }, 23, cjs.Ease.cubicIn)
        .to({ x: 929.85, y: 2788.1, alpha: 0 }, 15, cjs.Ease.quadOut)
        .to({ _off: true }, 1)
        .wait(16)
    );

    // _Mesh__13
    this.instance_21 = new lib.Interpolación17('synched', 0);
    this.instance_21.setTransform(632.55, 1330.6, 0.2811, 0.2811, 0, 0, 0, 0.2, 0);
    this.instance_21.alpha = 0;
    this.instance_21._off = true;

    this.timeline.addTween(
      cjs.Tween.get(this.instance_21)
        .wait(5)
        .to({ _off: false }, 0)
        .wait(1)
        .to({ regX: 0.4, regY: 0.2, scaleX: 0.4146, scaleY: 0.4146, x: 655.05, y: 1397.2, alpha: 0.1856 }, 0)
        .wait(1)
        .to({ scaleX: 0.5455, scaleY: 0.5455, x: 677.1, y: 1462.5, alpha: 0.3678 }, 0)
        .wait(1)
        .to({ scaleX: 0.6715, scaleY: 0.6715, x: 698.3, y: 1525.35, alpha: 0.543 }, 0)
        .wait(1)
        .to({ scaleX: 0.7902, scaleY: 0.7902, x: 718.3, y: 1584.55, alpha: 0.7082 }, 0)
        .wait(1)
        .to({ scaleX: 0.9, scaleY: 0.9, x: 736.85, y: 1639.35, alpha: 0.8609 }, 0)
        .wait(1)
        .to({ regX: 0, regY: 0, scaleX: 1, scaleY: 1, x: 753.5, y: 1689, alpha: 1 }, 0)
        .to({ y: 1729.25 }, 23, cjs.Ease.cubicIn)
        .to({ x: 726.85, y: 2788.1, alpha: 0 }, 15, cjs.Ease.quadOut)
        .to({ _off: true }, 1)
        .wait(11)
    );

    // _Mesh__14
    this.instance_22 = new lib.Interpolación18('synched', 0);
    this.instance_22.setTransform(659.95, 1286.7, 0.2811, 0.2811);
    this.instance_22.alpha = 0;

    this.timeline.addTween(
      cjs.Tween.get(this.instance_22)
        .wait(1)
        .to({ regX: 0.6, regY: 0.1, scaleX: 0.4146, scaleY: 0.4146, x: 695.65, y: 1332.45, alpha: 0.1856 }, 0)
        .wait(1)
        .to({ scaleX: 0.5455, scaleY: 0.5455, x: 730.55, y: 1377.25, alpha: 0.3678 }, 0)
        .wait(1)
        .to({ scaleX: 0.6715, scaleY: 0.6715, x: 764.05, y: 1420.4, alpha: 0.543 }, 0)
        .wait(1)
        .to({ scaleX: 0.7902, scaleY: 0.7902, x: 795.65, y: 1461.15, alpha: 0.7082 }, 0)
        .wait(1)
        .to({ scaleX: 0.9, scaleY: 0.9, x: 824.95, y: 1498.75, alpha: 0.8609 }, 0)
        .wait(1)
        .to({ regX: 0, regY: 0, scaleX: 1, scaleY: 1, x: 851, y: 1532.9, alpha: 1 }, 0)
        .to({ y: 1573.15 }, 23, cjs.Ease.cubicIn)
        .to({ x: 824.35, y: 2788.1, alpha: 0 }, 19, cjs.Ease.quadOut)
        .to({ _off: true }, 1)
        .wait(12)
    );

    // _Mesh__15
    this.instance_23 = new lib.Interpolación19('synched', 0);
    this.instance_23.setTransform(592.45, 1041.85, 0.2811, 0.2811, 0, 0, 0, 0.2, 0);
    this.instance_23.alpha = 0;
    this.instance_23._off = true;

    this.timeline.addTween(
      cjs.Tween.get(this.instance_23)
        .wait(2)
        .to({ _off: false }, 0)
        .wait(1)
        .to({ regX: 0.6, regY: 0.1, scaleX: 0.4146, scaleY: 0.4146, x: 596, y: 971.35, alpha: 0.1856 }, 0)
        .wait(1)
        .to({ scaleX: 0.5455, scaleY: 0.5455, x: 599.45, y: 902.15, alpha: 0.3678 }, 0)
        .wait(1)
        .to({ scaleX: 0.6715, scaleY: 0.6715, x: 602.7, y: 835.6, alpha: 0.543 }, 0)
        .wait(1)
        .to({ scaleX: 0.7902, scaleY: 0.7902, x: 605.75, y: 772.9, alpha: 0.7082 }, 0)
        .wait(1)
        .to({ scaleX: 0.9, scaleY: 0.9, x: 608.65, y: 714.85, alpha: 0.8609 }, 0)
        .wait(1)
        .to({ regX: 0, regY: 0, scaleX: 1, scaleY: 1, x: 610.85, y: 661.95, alpha: 1 }, 0)
        .to({ y: 702.2 }, 23, cjs.Ease.cubicIn)
        .to({ x: 584.2, y: 2788.1, alpha: 0 }, 15, cjs.Ease.quadOut)
        .to({ _off: true }, 1)
        .wait(14)
    );

    // _Mesh__16
    this.instance_24 = new lib.Interpolación20('synched', 0);
    this.instance_24.setTransform(496.9, 977.4, 0.2811, 0.2811, 0, 0, 0, 0.2, 0);
    this.instance_24.alpha = 0;

    this.timeline.addTween(
      cjs.Tween.get(this.instance_24)
        .wait(1)
        .to({ regX: 0.7, regY: 0.2, scaleX: 0.4146, scaleY: 0.4146, x: 455.15, y: 876.35, alpha: 0.1856 }, 0)
        .wait(1)
        .to({ scaleX: 0.5455, scaleY: 0.5455, x: 414.1, y: 777.15, alpha: 0.3678 }, 0)
        .wait(1)
        .to({ scaleX: 0.6715, scaleY: 0.6715, x: 374.55, y: 681.8, alpha: 0.543 }, 0)
        .wait(1)
        .to({ scaleX: 0.7902, scaleY: 0.7902, x: 337.3, y: 591.8, alpha: 0.7082 }, 0)
        .wait(1)
        .to({ scaleX: 0.9, scaleY: 0.9, x: 302.85, y: 508.65, alpha: 0.8609 }, 0)
        .wait(1)
        .to({ regX: 0, regY: 0, scaleX: 1, scaleY: 1, x: 271, y: 432.75, alpha: 1 }, 0)
        .to({ y: 473 }, 23, cjs.Ease.cubicIn)
        .to({ x: 244.35, y: 2788.1, alpha: 0 }, 15, cjs.Ease.quadOut)
        .to({ _off: true }, 1)
        .wait(16)
    );

    // _Mesh__17
    this.instance_25 = new lib.Interpolación21('synched', 0);
    this.instance_25.setTransform(443.95, 931.25, 0.2811, 0.2811);
    this.instance_25.alpha = 0;

    this.timeline.addTween(
      cjs.Tween.get(this.instance_25)
        .wait(1)
        .to({ regX: 0.7, regY: 0.2, scaleX: 0.4146, scaleY: 0.4146, x: 377.15, y: 808.3, alpha: 0.1856 }, 0)
        .wait(1)
        .to({ scaleX: 0.5455, scaleY: 0.5455, x: 311.45, y: 687.6, alpha: 0.3678 }, 0)
        .wait(1)
        .to({ scaleX: 0.6715, scaleY: 0.6715, x: 248.2, y: 571.55, alpha: 0.543 }, 0)
        .wait(1)
        .to({ scaleX: 0.7902, scaleY: 0.7902, x: 188.65, y: 462.1, alpha: 0.7082 }, 0)
        .wait(1)
        .to({ scaleX: 0.9, scaleY: 0.9, x: 133.55, y: 360.9, alpha: 0.8609 }, 0)
        .wait(1)
        .to({ regX: 0, regY: 0, scaleX: 1, scaleY: 1, x: 82.7, y: 268.6, alpha: 1 }, 0)
        .to({ y: 308.85 }, 23, cjs.Ease.cubicIn)
        .to({ x: 56.05, y: 2788.1, alpha: 0 }, 15, cjs.Ease.quadOut)
        .to({ _off: true }, 1)
        .wait(16)
    );

    // _Mesh__18
    this.instance_26 = new lib.Interpolación22('synched', 0);
    this.instance_26.setTransform(607.35, 974.9, 0.2811, 0.2811, 0, 0, 0, 0.2, 0.2);
    this.instance_26.alpha = 0;
    this.instance_26._off = true;

    this.timeline.addTween(
      cjs.Tween.get(this.instance_26)
        .wait(3)
        .to({ _off: false }, 0)
        .wait(1)
        .to({ regX: -1.7, regY: -1.7, scaleX: 0.4146, scaleY: 0.4146, x: 617.05, y: 871.8, alpha: 0.1856 }, 0)
        .wait(1)
        .to({ scaleX: 0.5455, scaleY: 0.5455, x: 627.05, y: 771.1, alpha: 0.3678 }, 0)
        .wait(1)
        .to({ scaleX: 0.6715, scaleY: 0.6715, x: 636.7, y: 674.3, alpha: 0.543 }, 0)
        .wait(1)
        .to({ scaleX: 0.7902, scaleY: 0.7902, x: 645.85, y: 583.05, alpha: 0.7082 }, 0)
        .wait(1)
        .to({ scaleX: 0.9, scaleY: 0.9, x: 654.25, y: 498.65, alpha: 0.8609 }, 0)
        .wait(1)
        .to({ regX: 0, regY: 0, scaleX: 1, scaleY: 1, x: 663.85, y: 423.75, alpha: 1 }, 0)
        .to({ y: 464 }, 23, cjs.Ease.cubicIn)
        .to({ x: 637.2, y: 2788.1, alpha: 0 }, 15, cjs.Ease.quadOut)
        .to({ _off: true }, 1)
        .wait(13)
    );

    // _Mesh__19
    this.instance_27 = new lib.Interpolación23('synched', 0);
    this.instance_27.setTransform(666.05, 1257.7, 0.2811, 0.2811);
    this.instance_27.alpha = 0;

    this.timeline.addTween(
      cjs.Tween.get(this.instance_27)
        .wait(1)
        .to({ regX: 0.4, regY: 0.1, scaleX: 0.4146, scaleY: 0.4146, x: 704.55, y: 1289.65, alpha: 0.1856 }, 0)
        .wait(1)
        .to({ scaleX: 0.5455, scaleY: 0.5455, x: 742.25, y: 1321, alpha: 0.3678 }, 0)
        .wait(1)
        .to({ scaleX: 0.6715, scaleY: 0.6715, x: 778.5, y: 1351.15, alpha: 0.543 }, 0)
        .wait(1)
        .to({ scaleX: 0.7902, scaleY: 0.7902, x: 812.7, y: 1379.6, alpha: 0.7082 }, 0)
        .wait(1)
        .to({ scaleX: 0.9, scaleY: 0.9, x: 844.35, y: 1405.9, alpha: 0.8609 }, 0)
        .wait(1)
        .to({ regX: 0, regY: 0, scaleX: 1, scaleY: 1, x: 872.75, y: 1429.75, alpha: 1 }, 0)
        .to({ y: 1470 }, 23, cjs.Ease.cubicIn)
        .to({ x: 846.1, y: 2788.1, alpha: 0 }, 15, cjs.Ease.quadOut)
        .to({ _off: true }, 1)
        .wait(16)
    );

    // _Mesh__20
    this.instance_28 = new lib.Interpolación24('synched', 0);
    this.instance_28.setTransform(670.65, 1210.8, 0.2811, 0.2811, 0, 0, 0, 0.2, 0);
    this.instance_28.alpha = 0;

    this.timeline.addTween(
      cjs.Tween.get(this.instance_28)
        .wait(1)
        .to({ regX: 0.3, regY: 0.1, scaleX: 0.4146, scaleY: 0.4146, x: 711.2, y: 1220.5, alpha: 0.1856 }, 0)
        .wait(1)
        .to({ scaleX: 0.5455, scaleY: 0.5455, x: 751, y: 1230, alpha: 0.3678 }, 0)
        .wait(1)
        .to({ scaleX: 0.6715, scaleY: 0.6715, x: 789.25, y: 1239.15, alpha: 0.543 }, 0)
        .wait(1)
        .to({ scaleX: 0.7902, scaleY: 0.7902, x: 825.35, y: 1247.8, alpha: 0.7082 }, 0)
        .wait(1)
        .to({ scaleX: 0.9, scaleY: 0.9, x: 858.7, y: 1255.75, alpha: 0.8609 }, 0)
        .wait(1)
        .to({ regX: 0, regY: 0, scaleX: 1, scaleY: 1, x: 889, y: 1262.95, alpha: 1 }, 0)
        .to({ y: 1303.2 }, 23, cjs.Ease.cubicIn)
        .to({ x: 862.35, y: 2788.1, alpha: 0 }, 15, cjs.Ease.quadOut)
        .to({ _off: true }, 1)
        .wait(16)
    );

    // _Mesh__21
    this.instance_29 = new lib.Interpolación25('synched', 0);
    this.instance_29.setTransform(664.2, 916.9, 0.2811, 0.2811, 0, 0, 0, 0.2, 0.2);
    this.instance_29.alpha = 0;
    this.instance_29._off = true;

    this.timeline.addTween(
      cjs.Tween.get(this.instance_29)
        .wait(5)
        .to({ _off: false }, 0)
        .wait(1)
        .to({ regX: 0.4, regY: 0.1, scaleX: 0.4146, scaleY: 0.4146, x: 701.75, y: 787, alpha: 0.1856 }, 0)
        .wait(1)
        .to({ scaleX: 0.5455, scaleY: 0.5455, x: 738.55, y: 659.6, alpha: 0.3678 }, 0)
        .wait(1)
        .to({ scaleX: 0.6715, scaleY: 0.6715, x: 773.95, y: 537, alpha: 0.543 }, 0)
        .wait(1)
        .to({ scaleX: 0.7902, scaleY: 0.7902, x: 807.3, y: 421.55, alpha: 0.7082 }, 0)
        .wait(1)
        .to({ scaleX: 0.9, scaleY: 0.9, x: 838.15, y: 314.65, alpha: 0.8609 }, 0)
        .wait(1)
        .to({ regX: 0, regY: 0, scaleX: 1, scaleY: 1, x: 866.1, y: 217.5, alpha: 1 }, 0)
        .to({ y: 257.75 }, 23, cjs.Ease.cubicIn)
        .to({ x: 839.45, y: 2788.1, alpha: 0 }, 15, cjs.Ease.quadOut)
        .to({ _off: true }, 1)
        .wait(11)
    );

    // _Mesh__22
    this.instance_30 = new lib.Interpolación26('synched', 0);
    this.instance_30.setTransform(689.55, 1075.45, 0.2811, 0.2811, 0, 0, 0, 0.2, 0.2);
    this.instance_30.alpha = 0;

    this.timeline.addTween(
      cjs.Tween.get(this.instance_30)
        .wait(1)
        .to({ regX: 0.3, regY: 0.1, scaleX: 0.4146, scaleY: 0.4146, x: 739.05, y: 1020.8, alpha: 0.1856 }, 0)
        .wait(1)
        .to({ scaleX: 0.5455, scaleY: 0.5455, x: 787.65, y: 967.2, alpha: 0.3678 }, 0)
        .wait(1)
        .to({ scaleX: 0.6715, scaleY: 0.6715, x: 834.4, y: 915.65, alpha: 0.543 }, 0)
        .wait(1)
        .to({ scaleX: 0.7902, scaleY: 0.7902, x: 878.5, y: 867.15, alpha: 0.7082 }, 0)
        .wait(1)
        .to({ scaleX: 0.9, scaleY: 0.9, x: 919.2, y: 822.2, alpha: 0.8609 }, 0)
        .wait(1)
        .to({ regX: 0, regY: 0, scaleX: 1, scaleY: 1, x: 956.25, y: 781.4, alpha: 1 }, 0)
        .to({ y: 821.65 }, 23, cjs.Ease.cubicIn)
        .to({ x: 929.6, y: 2788.1, alpha: 0 }, 15, cjs.Ease.quadOut)
        .to({ _off: true }, 1)
        .wait(16)
    );

    // _Mesh__23
    this.instance_31 = new lib.Interpolación27('synched', 0);
    this.instance_31.setTransform(738.95, 1231.55, 0.2811, 0.2811, 0, 0, 0, 0.2, 0);
    this.instance_31.alpha = 0;

    this.timeline.addTween(
      cjs.Tween.get(this.instance_31)
        .wait(1)
        .to({ regX: 0.3, regY: 0.1, scaleX: 0.4146, scaleY: 0.4146, x: 811.9, y: 1251.1, alpha: 0.1856 }, 0)
        .wait(1)
        .to({ scaleX: 0.5455, scaleY: 0.5455, x: 883.5, y: 1270.25, alpha: 0.3678 }, 0)
        .wait(1)
        .to({ scaleX: 0.6715, scaleY: 0.6715, x: 952.4, y: 1288.7, alpha: 0.543 }, 0)
        .wait(1)
        .to({ scaleX: 0.7902, scaleY: 0.7902, x: 1017.35, y: 1306.1, alpha: 0.7082 }, 0)
        .wait(1)
        .to({ scaleX: 0.9, scaleY: 0.9, x: 1077.35, y: 1322.2, alpha: 0.8609 }, 0)
        .wait(1)
        .to({ regX: 0, regY: 0, scaleX: 1, scaleY: 1, x: 1131.95, y: 1336.75, alpha: 1 }, 0)
        .to({ y: 1377 }, 23, cjs.Ease.cubicIn)
        .to({ x: 1105.3, y: 2788.1, alpha: 0 }, 14, cjs.Ease.quadOut)
        .to({ _off: true }, 12)
        .wait(6)
    );

    // _Mesh__24
    this.instance_32 = new lib.Interpolación28('synched', 0);
    this.instance_32.setTransform(710.65, 1469.35, 0.2811, 0.2811, 0, 0, 0, 0.2, 0.2);
    this.instance_32.alpha = 0;
    this.instance_32._off = true;

    this.timeline.addTween(
      cjs.Tween.get(this.instance_32)
        .wait(4)
        .to({ _off: false }, 0)
        .wait(1)
        .to({ regX: 0.4, scaleX: 0.4146, scaleY: 0.4146, x: 770.2, y: 1601.75, alpha: 0.1856 }, 0)
        .wait(1)
        .to({ scaleX: 0.5455, scaleY: 0.5455, x: 828.65, y: 1731.6, alpha: 0.3678 }, 0)
        .wait(1)
        .to({ scaleX: 0.6715, scaleY: 0.6715, x: 884.85, y: 1856.55, alpha: 0.543 }, 0)
        .wait(1)
        .to({ scaleX: 0.7902, scaleY: 0.7902, x: 937.8, y: 1974.3, alpha: 0.7082 }, 0)
        .wait(1)
        .to({ scaleX: 0.9, scaleY: 0.9, x: 986.8, y: 2083.3, alpha: 0.8609 }, 0)
        .wait(1)
        .to({ regX: 0, regY: 0, scaleX: 1, scaleY: 1, x: 1031.25, y: 2182.45, alpha: 1 }, 0)
        .to({ y: 2222.7 }, 23, cjs.Ease.cubicIn)
        .to({ x: 1004.6, y: 2788.1, alpha: 0 }, 25, cjs.Ease.quadOut)
        .to({ _off: true }, 1)
        .wait(2)
    );

    // _Mesh__25
    this.instance_33 = new lib.Interpolación29('synched', 0);
    this.instance_33.setTransform(710.35, 1320.4, 0.2811, 0.2811, 0, 0, 0, 0.2, 0);
    this.instance_33.alpha = 0;

    this.timeline.addTween(
      cjs.Tween.get(this.instance_33)
        .wait(1)
        .to({ regX: 0.5, regY: 0.3, scaleX: 0.4146, scaleY: 0.4146, x: 769.85, y: 1382.2, alpha: 0.1856 }, 0)
        .wait(1)
        .to({ scaleX: 0.5455, scaleY: 0.5455, x: 828.1, y: 1442.8, alpha: 0.3678 }, 0)
        .wait(1)
        .to({ scaleX: 0.6715, scaleY: 0.6715, x: 884.25, y: 1501.05, alpha: 0.543 }, 0)
        .wait(1)
        .to({ scaleX: 0.7902, scaleY: 0.7902, x: 937.1, y: 1556, alpha: 0.7082 }, 0)
        .wait(1)
        .to({ scaleX: 0.9, scaleY: 0.9, x: 986, y: 1606.8, alpha: 0.8609 }, 0)
        .wait(1)
        .to({ regX: 0, regY: 0, scaleX: 1, scaleY: 1, x: 1030.25, y: 1652.8, alpha: 1 }, 0)
        .to({ y: 1693.05 }, 23, cjs.Ease.cubicIn)
        .to({ x: 1003.6, y: 2788.1, alpha: 0 }, 25, cjs.Ease.quadOut)
        .to({ _off: true }, 1)
        .wait(6)
    );

    // _Mesh__26
    this.instance_34 = new lib.Interpolación30('synched', 0);
    this.instance_34.setTransform(445.55, 1301.2, 0.2811, 0.2811);
    this.instance_34.alpha = 0;
    this.instance_34._off = true;

    this.timeline.addTween(
      cjs.Tween.get(this.instance_34)
        .wait(5)
        .to({ _off: false }, 0)
        .wait(1)
        .to({ regX: 0.4, regY: 0.2, scaleX: 0.4146, scaleY: 0.4146, x: 379.35, y: 1353.85, alpha: 0.1856 }, 0)
        .wait(1)
        .to({ scaleX: 0.5455, scaleY: 0.5455, x: 314.35, y: 1405.45, alpha: 0.3678 }, 0)
        .wait(1)
        .to({ scaleX: 0.6715, scaleY: 0.6715, x: 251.85, y: 1455.15, alpha: 0.543 }, 0)
        .wait(1)
        .to({ scaleX: 0.7902, scaleY: 0.7902, x: 192.9, y: 1501.9, alpha: 0.7082 }, 0)
        .wait(1)
        .to({ scaleX: 0.9, scaleY: 0.9, x: 138.4, y: 1545.25, alpha: 0.8609 }, 0)
        .wait(1)
        .to({ regX: 0, regY: 0, scaleX: 1, scaleY: 1, x: 88.4, y: 1584.45, alpha: 1 }, 0)
        .to({ y: 1624.7 }, 23, cjs.Ease.cubicIn)
        .to({ x: 61.75, y: 2788.1, alpha: 0 }, 13, cjs.Ease.quadOut)
        .to({ _off: true }, 13)
        .wait(1)
    );

    // _Mesh__27
    this.instance_35 = new lib.Interpolación31('synched', 0);
    this.instance_35.setTransform(455.05, 1159.8, 0.2811, 0.2811);
    this.instance_35.alpha = 0;
    this.instance_35._off = true;

    this.timeline.addTween(
      cjs.Tween.get(this.instance_35)
        .wait(1)
        .to({ _off: false }, 0)
        .wait(1)
        .to({ regX: 0.5, regY: 0.3, scaleX: 0.4146, scaleY: 0.4146, x: 393.45, y: 1145.35, alpha: 0.1856 }, 0)
        .wait(1)
        .to({ scaleX: 0.5455, scaleY: 0.5455, x: 332.85, y: 1131.15, alpha: 0.3678 }, 0)
        .wait(1)
        .to({ scaleX: 0.6715, scaleY: 0.6715, x: 274.65, y: 1117.5, alpha: 0.543 }, 0)
        .wait(1)
        .to({ scaleX: 0.7902, scaleY: 0.7902, x: 219.7, y: 1104.6, alpha: 0.7082 }, 0)
        .wait(1)
        .to({ scaleX: 0.9, scaleY: 0.9, x: 168.9, y: 1092.65, alpha: 0.8609 }, 0)
        .wait(1)
        .to({ regX: 0, regY: 0, scaleX: 1, scaleY: 1, x: 122.2, y: 1081.55, alpha: 1 }, 0)
        .to({ y: 1121.8 }, 23, cjs.Ease.cubicIn)
        .to({ x: 95.55, y: 2788.1, alpha: 0 }, 25, cjs.Ease.quadOut)
        .to({ _off: true }, 1)
        .wait(5)
    );

    // _Mesh__28
    this.instance_36 = new lib.Interpolación32('synched', 0);
    this.instance_36.setTransform(467.5, 1458.4, 0.2811, 0.2811, 0, 0, 0, 0.2, 0);
    this.instance_36.alpha = 0;
    this.instance_36._off = true;

    this.timeline.addTween(
      cjs.Tween.get(this.instance_36)
        .wait(6)
        .to({ _off: false }, 0)
        .wait(1)
        .to({ regX: 0.5, regY: 0.3, scaleX: 0.4146, scaleY: 0.4146, x: 411.7, y: 1585.7, alpha: 0.1856 }, 0)
        .wait(1)
        .to({ scaleX: 0.5455, scaleY: 0.5455, x: 356.9, y: 1710.55, alpha: 0.3678 }, 0)
        .wait(1)
        .to({ scaleX: 0.6715, scaleY: 0.6715, x: 304.2, y: 1830.65, alpha: 0.543 }, 0)
        .wait(1)
        .to({ scaleX: 0.7902, scaleY: 0.7902, x: 254.5, y: 1943.85, alpha: 0.7082 }, 0)
        .wait(1)
        .to({ scaleX: 0.9, scaleY: 0.9, x: 208.5, y: 2048.55, alpha: 0.8609 }, 0)
        .wait(1)
        .to({ regX: 0, regY: 0, scaleX: 1, scaleY: 1, x: 166.4, y: 2143.6, alpha: 1 }, 0)
        .to({ y: 2183.85 }, 23, cjs.Ease.cubicIn)
        .to({ x: 139.75, y: 2788.1, alpha: 0 }, 25, cjs.Ease.quadOut)
        .wait(1)
    );

    // _Mesh__29
    this.instance_37 = new lib.Interpolación33('synched', 0);
    this.instance_37.setTransform(554.15, 1402.1, 0.2811, 0.2811, 0, 0, 0, 0.2, 0.2);
    this.instance_37.alpha = 0;

    this.timeline.addTween(
      cjs.Tween.get(this.instance_37)
        .wait(1)
        .to({ regX: 0.5, regY: 0.3, scaleX: 0.4146, scaleY: 0.4146, x: 539.5, y: 1502.55, alpha: 0.1856 }, 0)
        .wait(1)
        .to({ scaleX: 0.5455, scaleY: 0.5455, x: 525, y: 1601.15, alpha: 0.3678 }, 0)
        .wait(1)
        .to({ scaleX: 0.6715, scaleY: 0.6715, x: 511.15, y: 1696, alpha: 0.543 }, 0)
        .wait(1)
        .to({ scaleX: 0.7902, scaleY: 0.7902, x: 498.05, y: 1785.4, alpha: 0.7082 }, 0)
        .wait(1)
        .to({ scaleX: 0.9, scaleY: 0.9, x: 485.9, y: 1868.05, alpha: 0.8609 }, 0)
        .wait(1)
        .to({ regX: 0, regY: 0, scaleX: 1, scaleY: 1, x: 474.6, y: 1943.25, alpha: 1 }, 0)
        .to({ y: 1983.5 }, 23, cjs.Ease.cubicIn)
        .to({ x: 447.95, y: 2788.1, alpha: 0 }, 25, cjs.Ease.quadOut)
        .to({ _off: true }, 1)
        .wait(6)
    );

    // _Mesh__30
    this.instance_38 = new lib.Interpolación34('synched', 0);
    this.instance_38.setTransform(511.85, 1248.8, 0.2811, 0.2811, 0, 0, 0, 0.2, 0);
    this.instance_38.alpha = 0;
    this.instance_38._off = true;

    this.timeline.addTween(
      cjs.Tween.get(this.instance_38)
        .wait(5)
        .to({ _off: false }, 0)
        .wait(1)
        .to({ regX: 0, scaleX: 0.4146, scaleY: 0.4146, x: 476.9367, y: 1276.5069, alpha: 0.1856 }, 0)
        .wait(1)
        .to({ scaleX: 0.5455, scaleY: 0.5455, x: 442.7298, y: 1303.6921, alpha: 0.3678 }, 0)
        .wait(1)
        .to({ scaleX: 0.6715, scaleY: 0.6715, x: 409.8239, y: 1329.8433, alpha: 0.543 }, 0)
        .wait(1)
        .to({ scaleX: 0.7902, scaleY: 0.7902, x: 378.8058, y: 1354.4942, alpha: 0.7082 }, 0)
        .wait(1)
        .to({ scaleX: 0.9, scaleY: 0.9, x: 350.1155, y: 1377.2953, alpha: 0.8609 }, 0)
        .wait(1)
        .to({ scaleX: 1, scaleY: 1, x: 324.2, y: 1398.05, alpha: 1 }, 0)
        .to({ y: 1438.3 }, 23, cjs.Ease.cubicIn)
        .to({ x: 297.55, y: 2788.1, alpha: 0 }, 15, cjs.Ease.quadOut)
        .to({ _off: true }, 11)
        .wait(1)
    );

    // _Mesh__31
    this.instance_39 = new lib.Interpolación35('synched', 0);
    this.instance_39.setTransform(533.45, 1202.4, 0.2811, 0.2811, 0, 0, 0, 0, 0.2);
    this.instance_39.alpha = 0;

    this.timeline.addTween(
      cjs.Tween.get(this.instance_39)
        .wait(1)
        .to({ regY: 0, scaleX: 0.4146, scaleY: 0.4146, x: 508.8712, y: 1207.9935, alpha: 0.1856 }, 0)
        .wait(1)
        .to({ scaleX: 0.5455, scaleY: 0.5455, x: 484.7551, y: 1213.5307, alpha: 0.3678 }, 0)
        .wait(1)
        .to({ scaleX: 0.6715, scaleY: 0.6715, x: 461.5563, y: 1218.8573, alpha: 0.543 }, 0)
        .wait(1)
        .to({ scaleX: 0.7902, scaleY: 0.7902, x: 439.6884, y: 1223.8783, alpha: 0.7082 }, 0)
        .wait(1)
        .to({ scaleX: 0.9, scaleY: 0.9, x: 419.4616, y: 1228.5226, alpha: 0.8609 }, 0)
        .wait(1)
        .to({ scaleX: 1, scaleY: 1, x: 401.05, y: 1232.95, alpha: 1 }, 0)
        .to({ y: 1273.2 }, 23, cjs.Ease.cubicIn)
        .to({ x: 374.4, y: 2788.1, alpha: 0 }, 25, cjs.Ease.quadOut)
        .to({ _off: true }, 1)
        .wait(6)
    );

    // Modo_de_aislamiento
    this.shape = new cjs.Shape();
    this.shape.graphics.f('#EBD447').s().p('EhgYDLbMAAAmW1MDAxAAAMAAAGW1g');
    this.shape.setTransform(589.5, 1278);

    this.timeline.addTween(cjs.Tween.get(this.shape).to({ _off: true }, 1).wait(60));

    this._renderFirstFrame();
  }).prototype = p = new lib.AnMovieClip();
  p.nominalBounds = new cjs.Rectangle(562.1, 1254.1, 644.3000000000001, 1700.9);
  // library properties:
  lib.properties = {
    id: '3180DAF31AD04EC9974E438407360920',
    width: 1179,
    height: 2556,
    fps: 30,
    color: '#EBD447',
    opacity: 1.0,
    manifest: [{ src: 'assets/images/img/confeti_atlas_1.png?1722896630954', id: 'confeti_atlas_1' }],
    preloads: [],
  };

  // bootstrap callback support:

  (lib.Stage = function (canvas) {
    createjs.Stage.call(this, canvas);
  }).prototype = p = new createjs.Stage();

  p.setAutoPlay = function (autoPlay) {
    this.tickEnabled = autoPlay;
  };
  p.play = function () {
    this.tickEnabled = true;
    this.getChildAt(0).gotoAndPlay(this.getTimelinePosition());
  };
  p.stop = function (ms) {
    if (ms) this.seek(ms);
    this.tickEnabled = false;
  };
  p.seek = function (ms) {
    this.tickEnabled = true;
    this.getChildAt(0).gotoAndStop((lib.properties.fps * ms) / 1000);
  };
  p.getDuration = function () {
    return (this.getChildAt(0).totalFrames / lib.properties.fps) * 1000;
  };

  p.getTimelinePosition = function () {
    return (this.getChildAt(0).currentFrame / lib.properties.fps) * 1000;
  };

  an.bootcompsLoaded = an.bootcompsLoaded || [];
  if (!an.bootstrapListeners) {
    an.bootstrapListeners = [];
  }

  an.bootstrapCallback = function (fnCallback) {
    an.bootstrapListeners.push(fnCallback);
    if (an.bootcompsLoaded.length > 0) {
      for (var i = 0; i < an.bootcompsLoaded.length; ++i) {
        fnCallback(an.bootcompsLoaded[i]);
      }
    }
  };

  an.compositions = an.compositions || {};
  an.compositions['3180DAF31AD04EC9974E438407360920'] = {
    getStage: function () {
      return exportRoot.stage;
    },
    getLibrary: function () {
      return lib;
    },
    getSpriteSheet: function () {
      return ss;
    },
    getImages: function () {
      return img;
    },
  };

  an.compositionLoaded = function (id) {
    an.bootcompsLoaded.push(id);
    for (var j = 0; j < an.bootstrapListeners.length; j++) {
      an.bootstrapListeners[j](id);
    }
  };

  an.getComposition = function (id) {
    return an.compositions[id];
  };

  an.makeResponsive = function (isResp, respDim, isScale, scaleType, domContainers) {
    var lastW,
      lastH,
      lastS = 1;
    window.addEventListener('resize', resizeCanvas);
    resizeCanvas();
    function resizeCanvas() {
      var w = lib.properties.width,
        h = lib.properties.height;
      var iw = window.innerWidth,
        ih = window.innerHeight;
      var pRatio = window.devicePixelRatio || 1,
        xRatio = iw / w,
        yRatio = ih / h,
        sRatio = 1;
      if (isResp) {
        if ((respDim == 'width' && lastW == iw) || (respDim == 'height' && lastH == ih)) {
          sRatio = lastS;
        } else if (!isScale) {
          if (iw < w || ih < h) sRatio = Math.min(xRatio, yRatio);
        } else if (scaleType == 1) {
          sRatio = Math.min(xRatio, yRatio);
        } else if (scaleType == 2) {
          sRatio = Math.max(xRatio, yRatio);
        }
      }
      domContainers[0].width = w * pRatio * sRatio;
      domContainers[0].height = h * pRatio * sRatio;
      domContainers.forEach(function (container) {
        container.style.width = 'auto';
        container.style.height = '100%';
      });
      stage.scaleX = pRatio * sRatio;
      stage.scaleY = pRatio * sRatio;
      lastW = iw;
      lastH = ih;
      lastS = sRatio;
      stage.tickOnUpdate = false;
      stage.update();
      stage.tickOnUpdate = true;
    }
  };
  an.handleSoundStreamOnTick = function (event) {
    if (!event.paused) {
      var stageChild = stage.getChildAt(0);
      if (!stageChild.paused || stageChild.ignorePause) {
        stageChild.syncStreamSounds();
      }
    }
  };
  an.handleFilterCache = function (event) {
    if (!event.paused) {
      var target = event.target;
      if (target) {
        if (target.filterCacheList) {
          for (var index = 0; index < target.filterCacheList.length; index++) {
            var cacheInst = target.filterCacheList[index];
            if (cacheInst.startFrame <= target.currentFrame && target.currentFrame <= cacheInst.endFrame) {
              cacheInst.instance.cache(cacheInst.x, cacheInst.y, cacheInst.w, cacheInst.h);
            }
          }
        }
      }
    }
  };
})((createjs = createjs || {}), (AdobeAn = AdobeAn || {}));
var createjs, AdobeAn;
!function(){try{var e="undefined"!=typeof window?window:"undefined"!=typeof global?global:"undefined"!=typeof globalThis?globalThis:"undefined"!=typeof self?self:{},n=(new e.Error).stack;n&&(e._sentryDebugIds=e._sentryDebugIds||{},e._sentryDebugIds[n]="8dded8ba-4801-5fd2-ac4e-2c5198ee74ab")}catch(e){}}();
//# debugId=8dded8ba-4801-5fd2-ac4e-2c5198ee74ab
